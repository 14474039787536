Roles.builder = {

  mode : 'add',
  roleID : null,
  instID : null,
  siteID : null,
  deadlinePeriod : '',

  init : function ( mode, roleJSON ) {
    Roles.builder.mode = mode;

    if ( mode === 'add' ) {
      Roles.builder.submitFunction = Roles.builder.addingSubmitFunction;
    }
    else if ( mode === 'edit' ) {
      Roles.builder.submitFunction = Roles.builder.editingSubmitFunction;

      if ( roleJSON !== undefined ) {
        var roleObj = JSON.parse ( roleJSON );
        
        Roles.builder.roleID = Number ( roleObj.id );
        Roles.builder.deadlinePeriod = roleObj.deadline_period;

        Roles.builder.QUALS.arr = roleObj.QUALs;
        Roles.builder.OC.arr    = roleObj.OCs;
        Roles.builder.ILT.arr   = roleObj.ILTs;
        Roles.builder.OL.arr    = roleObj.OLs;
      }
    }

    Roles.builder.steps = new Steps ( Roles.builder, {
      'step-1' : {
        id : 'step-1',
        init: function() {
          Roles.builder.instID = $('#location-role-inst').val();
          Roles.builder.siteID = $('#location-role-site').val();

          Roles.builder.steps.arr['step-1'].refreshEvents();
        },
        nextStepFunction : function() {
          if ( $('input[name="role-name"]').val() === '' ) {
            main.flashData.show ( 'Please enter a name for this role.', 'negative', -1 );
          }
          else if ( $('#location-role-inst').val() === '' ) {
            main.flashData.show ( 'Please select an organisation.', 'negative', -1 );
          }
          else if ( $('#location-role-site').val() === '' && $('#location-role-inst').val() !== '0' ) {
            main.flashData.show ( 'Please select a site.', 'negative', -1 );
          }
          else {
            Roles.builder.steps.tickStep ( 0 );
            Roles.builder.steps.nextStep();

            main.flashData.hide();
          }
        },
        refreshEvents : function() {
          $('#location-role-inst')
            .off ( 'change', Roles.builder.steps.arr['step-1'].updateSelectedInst )
            .on  ( 'change', Roles.builder.steps.arr['step-1'].updateSelectedInst);

          $('#location-role-site')
            .off ( 'change', Roles.builder.steps.arr['step-1'].updateSelectedSite )
            .on  ( 'change', Roles.builder.steps.arr['step-1'].updateSelectedSite);

          // - DEADLINE STEP 1
          $('#step-1 .deadline-selector')
            .off ( 'change' )
            .on  ( 'change', function ( evt ) {
              if ( $(this).val() === 'custom' ) {
                $(this).next('.custom-deadline').switchClass('dn', 'di');
                Roles.builder.steps.arr['step-1'].updateDeadlinePeriod();
              }
              else {
                $(this).next('.custom-deadline').switchClass('di', 'dn');
                Roles.builder.deadlinePeriod = '';
              }
            });

          $('#step-1 .custom-deadline-number')
            .off ( 'keyup' )
            .off ( 'change' )
            .on  ( 'keyup change', Roles.builder.steps.arr['step-1'].updateDeadlinePeriod );

          $('#step-1 .custom-deadline-unit')
            .off ( 'change' )
            .on  ( 'change', Roles.builder.steps.arr['step-1'].updateDeadlinePeriod );
        },
        updateDeadlinePeriod : function() {
          Roles.builder.deadlinePeriod = '+' + $('#step-1 .custom-deadline-number').val() + ' ' + $('#step-1 .custom-deadline-unit').val();
        },
        updateSelectedInst : function() {
          if ( $('#location-role-inst').val() === '' ) {
            $('#location-role-site').select_by_value('');
          }

          Roles.builder.instID = $('#location-role-inst').val();
          Roles.builder.siteID = $('#location-role-site').val();
        },
        updateSelectedSite : function() {
          Roles.builder.siteID = $('#location-role-site').val();
        },
        onShow : function() {
          Roles.builder.steps.arr['step-1'].refreshEvents();
        },
      },
      'step-2' : {
        id : 'step-2',
        init: function() {
          Roles.builder.QUALS.init();
          Roles.builder.OL.init();
          Roles.builder.OC.init();
          Roles.builder.ILT.init();
        },
        nextStepFunction : function() {
          Roles.builder.steps.tickStep ( 1 );
          Roles.builder.steps.nextStep();
        },
        backStepFunction : function() {
            Roles.builder.steps.backStep();
        },
        refreshEvents : function() {
          $('#add-qualifications-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
              evt.preventDefault();

              Roles.builder.QUALS.pool.show();
            });

          $('#add-OLs-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
              evt.preventDefault();

              Roles.builder.OL.pool.show();
            });

          $('#add-OCs-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
              evt.preventDefault();

              Roles.builder.OC.pool.show();
            });

          $('#add-ILTs-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
              evt.preventDefault();

              Roles.builder.ILT.pool.show();
            });

          // - DEADLINES STEP 2
          $('#step-2 .deadline-selector')
            .off ( 'change' )
            .on  ( 'change', function ( evt ) {
              var type = $(this).closest('table').attr('data-type'),
                  id, arr;

              switch ( type ) {
                case 'QUAL':
                  id = $(this).closest('tr').attr('data-qualification-id');
                  arr = Roles.builder.QUALS.arr;
                  break;
                case 'OL':
                  id = $(this).closest('tr').attr('data-ol-id');
                  arr = Roles.builder.OL.arr;
                  break;
                case 'OC':
                  id = $(this).closest('tr').attr('data-oc-id');
                  arr = Roles.builder.OC.arr;
                  break;
                case 'ILT':
                  id = $(this).closest('tr').attr('data-ilt-id');
                  arr = Roles.builder.ILT.arr;
                  break;
              }

              if ( $(this).val() === 'custom' ) {
                $(this).next('.custom-deadline').switchClass('dn', 'di');
              }
              else {
                $(this).next('.custom-deadline').switchClass('di', 'dn');
              }

              Roles.builder.steps.arr['step-2'].updateDeadline ( type, id );
            });

          $('.custom-deadline-number')
            .off ( 'keyup' )
            .off ( 'change' )
            .on  ( 'keyup change', function ( evt ) {
              var type = $(this).closest('table').attr('data-type'),
                  id;

              switch ( type ) {
                case 'QUAL':
                  id = $(this).closest('tr').attr('data-qualification-id');
                  break;
                case 'OL':
                  id = $(this).closest('tr').attr('data-ol-id');
                  break;
                case 'OC':
                  id = $(this).closest('tr').attr('data-oc-id');
                  break;
                case 'ILT':
                  id = $(this).closest('tr').attr('data-ilt-id');
                  break;
              }

              Roles.builder.steps.arr['step-2'].updateDeadline ( type, id );
            });

          $('.custom-deadline-unit')
            .off ( 'change' )
            .on  ( 'change', function ( evt ) {
              var type = $(this).closest('table').attr('data-type'),
                  id;

              switch ( type ) {
                case 'QUAL':
                  id = $(this).closest('tr').attr('data-qualification-id');
                  break;
                case 'OL':
                  id = $(this).closest('tr').attr('data-ol-id');
                  break;
                case 'OC':
                  id = $(this).closest('tr').attr('data-oc-id');
                  break;
                case 'ILT':
                  id = $(this).closest('tr').attr('data-ilt-id');
                  break;
              }

              Roles.builder.steps.arr['step-2'].updateDeadline ( type, id );
            });
          
          $("#DT-add-QUALs-to-role .remove-added-qualification-btn")
            .off ( "click" )
            .on  ( "click", function ( evt ) {
              evt.preventDefault();

              var id = $(this).closest("tr").data("qualification-id");
              // console.log ( id, Roles.builder.QUAL.arr, Roles.builder.QUAL.arr[id]);
              Roles.builder.QUALS.remove ( id );
            });
          
          $("#DT-add-OCs-to-role .remove-added-oc-btn")
            .off ( "click" )
            .on  ( "click", function ( evt ) {
              evt.preventDefault();

              var id = $(this).closest("tr").data("oc-id");
              // console.log ( id, Roles.builder.OC.arr, Roles.builder.OC.arr[id]);
              Roles.builder.OC.remove ( id );
            });
          
          $("#DT-add-ILTs-to-role .remove-added-ilt-btn")
            .off ( "click" )
            .on  ( "click", function ( evt ) {
              evt.preventDefault();

              var id = $(this).closest("tr").data("ilt-id");
              // console.log ( id, Roles.builder.ILT.arr, Roles.builder.ILT.arr[id]);
              Roles.builder.ILT.remove ( id );
            });

          $("#DT-add-OLs-to-role .remove-added-ol-btn")
            .off ( "click" )
            .on  ( "click", function ( evt ) {
              evt.preventDefault();

              var id = $(this).closest("tr").data("ol-id");
              // console.log ( id, Roles.builder.OL.arr, Roles.builder.OL.arr[id]);
              Roles.builder.OL.remove ( id );
            });

        },
        updateDeadline : function ( type, id ) {
          var tr, arr;

          switch ( type ) {
            case 'QUAL':
              tr  = $('#step-2 tr[data-qualification-id="' + id + '"]');
              arr = Roles.builder.QUALS.arr;
              break;
            case 'OL':
              tr  = $('#step-2 tr[data-ol-id="' + id + '"]');
              arr = Roles.builder.OL.arr;
              break;
            case 'OC':
              tr  = $('#step-2 tr[data-oc-id="' + id + '"]');
              arr = Roles.builder.OC.arr;
              break;
            case 'ILT':
              tr  = $('#step-2 tr[data-ilt-id="' + id + '"]');
              arr = Roles.builder.ILT.arr;
              break;
          }

          if ( $(tr).find('.deadline-selector').val() === 'custom' ) {
            var customNo = $(tr).find('.custom-deadline-number').val(),
                unit     = $(tr).find('.custom-deadline-unit').val(),
                valid    = validate.isInt ( customNo ) && customNo > 0;

            if ( valid ) {
              arr[id].deadlinePeriod = '+' + customNo + ' ' + unit;
            }
          }
          else {
            arr[id].deadlinePeriod = '';
          }

          // console.log(Roles.builder.QUALS.arr[1]);//
        },
        onShow : function() {
          Roles.builder.steps.arr['step-2'].refreshEvents();
        },
      },
    },
    Roles.builder.submitFunction);

    Roles.builder.steps.init();
    Roles.builder.steps.arr['step-1'].init();
    Roles.builder.steps.arr['step-2'].init();
  },

  updateSelection : function ( tableID, inputSelectorToUpdate ) {
    var selection = [];

    $('#'+tableID).find( '.selected' ).each ( function ( index, elem ) {
      selection.push ( Number ( $(elem).attr ( 'data-id' ) ) );
    });

    $(inputSelectorToUpdate).val ( JSON.stringify ( selection ) );
  },

  /*
   | ------------------------------
   | STEPS
   | ------------------------------
   */
  addingSubmitFunction : function() {
    $('form[name="add-role-form"]').submit ( function ( evt ) {
      evt.preventDefault();
      if ($(this).parsley().isValid()) {
        var sendData = {
          roleName       : $('input[name ="role-name"]').val(),
          deadlinePeriod : Roles.builder.deadlinePeriod,
          instID         : Roles.builder.instID,
          siteID         : Roles.builder.siteID,
          QUALs          : Roles.builder.QUALS.arr,
          OLs            : Roles.builder.OL.arr,
          OCs            : Roles.builder.OC.arr,
          ILTs           : Roles.builder.ILT.arr,
        };
 console.log('adding', sendData);
        $.ajax({
          url: '/admin/AJAX-roles/add-role',
          type: 'post',
          data: sendData,
          dataType: 'text',
          success: function ( returnedData ) {
            // console.log(returnedData);
            returnedData = JSON.parse(returnedData);
            // console.log(returnedData);
            if ( returnedData.success ) {
              // - SHOW THE FLASH MESSAGE
              main.flashData.show ( 'Role added successfully, redirecting...', 'positive', 4 );

              // - MARK THE LAST STEP AS TICKED
              Roles.builder.steps.tickStep ( 1 );

              // - REDIRECT
              setTimeout ( function() {
                window.location.href = '/admin/roles';
              }, 4200 );
            }
          },
          error: function(error) {
              console.log(error);
          },
        });
      }
      else {
        // console.log('not valid');
      }
    });

    $('#submit-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        Roles.builder.deadlinesInvalid = 0;

        $('form[name="add-role-form"] .deadline-selector').each (
          Roles.builder.checkCustomDeadlineFilled
        );

        if ( Roles.builder.deadlinesInvalid > 0 ) {
          main.flashData.show (
            'For any custom deadline periods, please enter a whole nuber value greater than 0.', 'negative', -1
          );
        }
        else {
          $('form[name="add-role-form"]').submit();
        }
      });
  },
  editingSubmitFunction : function () {
    $('form[name="edit-role-form"]').submit ( function ( evt ) {
      evt.preventDefault();
      if ( $(this).parsley().isValid() ) {
        var sendData = {
          roleID         : Roles.builder.roleID,
          roleName       : $('input[name ="role-name"]').val(),
          deadlinePeriod : Roles.builder.deadlinePeriod,
          instID         : Roles.builder.instID,
          siteID         : Roles.builder.siteID,
          QUALs          : Roles.builder.QUALS.arr,
          OLs            : Roles.builder.OL.arr,
          OCs            : Roles.builder.OC.arr,
          ILTs           : Roles.builder.ILT.arr,
        };
// console.log('editing', sendData);
        $.ajax({
          url: '/admin/AJAX-roles/edit-role',
          type: 'post',
          data: sendData,
          dataType: 'text',
          success: function ( returnedData ) {
            // console.log(returnedData);
            returnedData = JSON.parse(returnedData);
            // console.log(returnedData);
            if ( returnedData.success ) {
              // - SHOW THE FLASH MESSAGE
              main.flashData.show ( 'Role edited successfully, redirecting...', 'positive', 4 );

              // - MARK THE LAST STEP AS TICKED
              Roles.builder.steps.tickStep ( 1 );

              // - REDIRECT
              setTimeout ( function() {
                window.location.href = '/admin/roles';
              }, 4200 );
            }
          },
        });
      }
      else {
        // console.log('not valid');
      }
    });

    $('#submit-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        Roles.builder.deadlinesInvalid = 0;

        $('form[name="edit-role-form"] .deadline-selector').each (
          Roles.builder.checkCustomDeadlineFilled
        );

        if ( Roles.builder.deadlinesInvalid > 0 ) {
          main.flashData.show (
            'For any custom deadline periods, please enter a whole nuber value greater than 0.', 'negative', -1
          );
        }
        else {
          $('form[name="edit-role-form"]').submit();
        }
      });
  },
  steps : null,

  checkCustomDeadlineFilled : function ( index, elem ) {
    if ( $(elem).val() === 'custom' ) {
      var customNo = $(elem).next().find('.custom-deadline-number').val();

      $(elem).next().find('.custom-deadline-number')
        .removeClass ( 'invalid' );

      if ( ! validate.isInt ( customNo ) || String ( customNo ) === '0' ) {
        $(elem).next().find('.custom-deadline-number')
          .addClass ( 'invalid' );

        Roles.builder.deadlinesInvalid++;
      }
    }
  },

  show_POOL : function ( poolObj, popupTitle, ajaxUrl ) {
    popup.generatePopup({
      title: 'Add ' + popupTitle,
      method: 'ajax',
      ajaxUrl: ajaxUrl,
      data : {
        instID : Roles.builder.instID,
        siteID : Roles.builder.siteID,
      },
      maxWidth: 1000,
      maxHeight: 690,
      footer: false,
      success: function(returnedData) {
        poolObj.pool.setBtns();
        poolObj.pool.refreshEvents();
      },
    });
  },

  setBtns_POOL : function ( poolID, addBtnSelector, removeBtnSelector, itemsObj, rowIDKey ) {
    // console.log('poolID', poolID);
    // console.log('addBtnSelector', addBtnSelector);
    // console.log('removeBtnSelector', removeBtnSelector);
    // console.log('itemsObj', itemsObj);
    // console.log('rowIDKey', rowIDKey);
    $(addBtnSelector)
      .addClass ( 'btn-primary' )
      .removeClass ( 'disabled-btn' );

    $(removeBtnSelector)
      .addClass ( 'disabled-btn' )
      .removeClass ( 'btn-danger' );

    for ( var id in itemsObj.arr ) {
      $('#' + poolID + ' tr[' + rowIDKey + '=' + id + ']')
        .find(addBtnSelector)
          .addClass ( 'disabled-btn' )
          .removeClass ( 'btn-primary' );

      $('#' + poolID + ' tr[' + rowIDKey + '=' + id + ']')
        .find(removeBtnSelector)
          .addClass ( 'btn-danger' )
          .removeClass ( 'disabled-btn' );
    }
  },

  refreshEvents_POOL : function ( addBtnSelector, removeBtnSelector, itemsObj, rowIDKey ) {
    $(addBtnSelector)
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        itemsObj.add ( $(this).closest('tr').attr(rowIDKey) );
      });

    $(removeBtnSelector)
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        itemsObj.remove ( $(this).closest('tr').attr(rowIDKey) );
      });
  },

  add_POOL : function ( id, poolSelectorID, targetTableID, addBtnSelector, removeBtnSelector, removeAddedBtnSelector, itemsObj, rowIDKey, itemRef ) {
    if ( itemsObj.arr[id] === undefined ) {
      var poolTr         = $('#' + poolSelectorID + ' tr[' + rowIDKey + '=' + id + ']'),
          name           = poolTr.find('td:nth-child(1)').text(),
          addBtn         = poolTr.find(addBtnSelector),
          removeBtn      = poolTr.find(removeBtnSelector),
          removeBtnHTML        = '<div class="btn-bank-host fr">' +
                                   '<a class="' + removeAddedBtnSelector + ' btn btn-danger btn-bank">' +
                                     '<i class="fa fa-trash"></i>' +
                                   '</a>' +
                                 '</div>',
          deadlineSelectorHTML = '<select class="deadline-selector di">' +
                                   '<option value="">Not Set</option>' +
                                   '<option value="custom">Custom</option>' +
                                 '</select>' +
                                 '<div class="custom-deadline dn">' +
                                   '<input type="number" class="custom-deadline-number di" min="0" step="1" value="1">' +
                                   '<select class="custom-deadline-unit di">' +
                                     '<option value="days">Day(s)</option>' +
                                     '<option value="weeks">Week(s)</option>' +
                                     '<option value="months">Month(s)</option>' +
                                     '<option value="years">Year(s)</option>' +
                                   '</select>' +
                                 '</div>';

      // - CREATE AN ARRAY OF CELLS, SO THAT WE CAN ADD NAME OR NAME, LOCATION DEPENDING ON NEED
      var cells = [];
      var addCell = function ( index, elem ) {
        cells.push ( $(elem).text() );
      };
      poolTr.find('td.cell').each ( addCell );
      // - ALWAYS ADD THE NEXT 2 TO IT
      // - ADD THE DEADLINE SELECTOR TO CELLS ARRAY
      cells.push ( deadlineSelectorHTML );
      // - ADD THE REMOVE BUTTON TO THE CELLS ARRAY
      cells.push ( removeBtnHTML );

      itemsObj.arr[id] = ({
        id : id,
        deadlinePeriod : '',
      });

      // - SWITCH THE ADD QUALIFICATION BTN TO DISABLED
      addBtn.removeClass ( 'btn-primary' );
      addBtn.addClass ( 'disabled-btn' );

      // - SWITCH THE TRASH BTN TO ENABLED
      removeBtn.removeClass ( 'disabled-btn' );
      removeBtn.addClass ( 'btn-danger' );

      var rowNode = dataTable.tables[targetTableID].tbl.api().row.add(cells).draw().node();

      $(rowNode)
        .attr ( rowIDKey, id )
        .find('.'+removeAddedBtnSelector)
          .on ( 'click', function ( evt ) {
            itemsObj.remove ( id );
          });

      Roles.builder.steps.arr['step-2'].refreshEvents();
    }
    else {
      main.flashData.show ( 'This ' + itemRef + ' is already part of this role.', 'negative', 3 );
    }
  },

  remove_POOL : function ( id, poolSelectorID, targetTableID, addBtnSelector, removeBtnSelector, itemsObj, rowIDKey, itemRef ) {
    // console.log(itemsObj.arr, id, itemsObj.arr[id]);
    if ( itemsObj.arr[id] !== undefined ) {
      var fromPopup = $('#' + poolSelectorID + ' ' + addBtnSelector).length > 0,
          formTr    = $('#' + targetTableID + ' tr[' + rowIDKey + '=' + id + ']');

      dataTable.tables[targetTableID].tbl.api().row(formTr).remove().draw();

      delete itemsObj.arr[id];

      if ( fromPopup ) {
        var poolTr = $('#' + poolSelectorID + ' tr[' + rowIDKey + '=' + id + ']'),
        addBtn     = poolTr.find(addBtnSelector),
        removeBtn  = poolTr.find(removeBtnSelector);

        // - SWITCH THE ADD QUALIFICATION BTN TO ENABLED
        addBtn.removeClass ( 'disabled-btn' );
        addBtn.addClass ( 'btn-primary' );

        // - SWITCH THE TRASH BTN TO ENABLED
        removeBtn.removeClass ( 'btn-danger' );
        removeBtn.addClass ( 'disabled-btn' );
      }
    }
    else {
      main.flashData.show ( 'This ' + itemRef + ' not part of this role.', 'negative', 3 );
    }
  },

  QUALS : {
    arr : {},

    init : function() {
      Roles.builder.QUALS.initQUALTable();
    },

    initQUALTable : function() {
      /*
      |--------------------------------------------------------------------------
      | SETUP QUALS
      |--------------------------------------------------------------------------
       */

      dataTable.tables['DT-add-QUALs-to-role'] = {
        tbl : $('#DT-add-QUALs-to-role').dataTable({
          filter : false,
          paging : false,
          columnDefs : [
            {
              orderable : false,
              targets : 0,
            },
          ],
        }),
      };

      /*
      |--------------------------------------------------------------------------
      | ADDING QUALS TO ROLE
      |--------------------------------------------------------------------------
       */
    },

    pool : {
      show : function() {
        //Roles.builder.QUALS.pool.show
        Roles.builder.show_POOL (
          Roles.builder.QUALS,
          'TPaQs',
          '/admin/AJAX-roles/show-qualifications-pool'
        );
      },

      setBtns : function() {
        Roles.builder.setBtns_POOL (
          'DT-roles-QUALS-pool',
          '.add-qualification-btn',
          '.remove-qualification-btn',
          Roles.builder.QUALS,
          'data-qualification-id'
        );
      },

      refreshEvents : function () {
        Roles.builder.refreshEvents_POOL (
          '.add-qualification-btn',
          '.remove-qualification-btn',
          Roles.builder.QUALS,
          'data-qualification-id'
        );
      },
    },

    add : function ( id ) {
      Roles.builder.add_POOL (
        id,
        'DT-roles-QUALS-pool',
        'DT-add-QUALs-to-role',
        '.add-qualification-btn',
        '.remove-qualification-btn',
        'remove-added-qualification-btn',
        Roles.builder.QUALS,
        'data-qualification-id',
        'TPaQ'
      );
    },

    remove : function ( id ) {
      Roles.builder.remove_POOL (
        id,
        'DT-roles-QUALS-pool',
        'DT-add-QUALs-to-role',
        '.add-qualification-btn',
        '.remove-qualification-btn',
        Roles.builder.QUALS,
        'data-qualification-id',
        'TPaQ'
      );
    },
  },

  OL : {
    arr : {},

    init : function() {
      Roles.builder.OL.initOLTable();
    },

    initOLTable : function() {
      /*
      |--------------------------------------------------------------------------
      | SETUP ONLINE LEARNING COURSES
      |--------------------------------------------------------------------------
       */

      dataTable.tables['DT-add-OLs-to-role'] = {
        tbl : $('#DT-add-OLs-to-role').dataTable({
          filter : false,
          paging : false,
          columnDefs : [
            {
              orderable : false,
              targets : 0,
            },
          ],
        }),
      };

      /*
      |--------------------------------------------------------------------------
      | ADDING ONLINE LEARNING COURSES TO ROLE
      |--------------------------------------------------------------------------
       */
    },

    pool : {
      show : function() {
        //Roles.builder.OL.pool.show
        Roles.builder.show_POOL (
          Roles.builder.OL,
          'Online Learning Courses',
          '/admin/AJAX-roles/show-ol-pool'
        );
      },

      setBtns : function() {
        Roles.builder.setBtns_POOL (
          'DT-roles-OL-pool',
          '.add-ol-btn',
          '.remove-ol-btn',
          Roles.builder.OL,
          'data-ol-id'
        );
      },

      refreshEvents : function () {
        Roles.builder.refreshEvents_POOL (
          '.add-ol-btn',
          '.remove-ol-btn',
          Roles.builder.OL,
          'data-ol-id'
        );
      },
    },

    add : function ( id ) {
      Roles.builder.add_POOL (
        id,
        'DT-roles-OL-pool',
        'DT-add-OLs-to-role',
        '.add-ol-btn',
        '.remove-ol-btn',
        'remove-added-ol-btn',
        Roles.builder.OL,
        'data-ol-id',
        'Online Learning Course'
      );
    },

    remove : function ( id ) {
      Roles.builder.remove_POOL (
        id,
        'DT-roles-OL-pool',
        'DT-add-OLs-to-role',
        '.add-ol-btn',
        '.remove-ol-btn',
        Roles.builder.OL,
        'data-ol-id',
        'Online Learning Course'
      );
    },
  },

  OC : {
    arr : {},

    init : function() {
      Roles.builder.OC.initOCTable();
    },

    initOCTable : function() {
      /*
      |--------------------------------------------------------------------------
      | SETUP OCC COMP EXERCISES
      |--------------------------------------------------------------------------
       */

      dataTable.tables['DT-add-OCs-to-role'] = {
        tbl : $('#DT-add-OCs-to-role').dataTable({
          filter : false,
          paging : false,
          columnDefs : [
            {
              orderable : false,
              targets : 0,
            },
          ],
        }),
      };

      /*
      |--------------------------------------------------------------------------
      | ADDING OCC COMP EXERCISES TO ROLE
      |--------------------------------------------------------------------------
       */
    },

    pool : {
      show : function() {
        //Roles.builder.OC.pool.show
        Roles.builder.show_POOL (
          Roles.builder.OC,
          'Competencies',
          '/admin/AJAX-roles/show-oc-pool'
        );
      },

      setBtns : function() {
        Roles.builder.setBtns_POOL (
          'DT-roles-OC-pool',
          '.add-oc-btn',
          '.remove-oc-btn',
          Roles.builder.OC,
          'data-oc-id'
        );
      },

      refreshEvents : function () {
        Roles.builder.refreshEvents_POOL (
          '.add-oc-btn',
          '.remove-oc-btn',
          Roles.builder.OC,
          'data-oc-id'
        );
      },
    },

    add : function ( id ) {
      Roles.builder.add_POOL (
        id,
        'DT-roles-OC-pool',
        'DT-add-OCs-to-role',
        '.add-oc-btn',
        '.remove-oc-btn',
        'remove-added-oc-btn',
        Roles.builder.OC,
        'data-oc-id',
        'Competency'
      );
    },

    remove : function ( id ) {
      Roles.builder.remove_POOL (
        id,
        'DT-roles-OC-pool',
        'DT-add-OCs-to-role',
        '.add-oc-btn',
        '.remove-oc-btn',
        Roles.builder.OC,
        'data-oc-id',
        'Competency'
      );
    },
  },

  ILT : {
    arr : {},

    init : function() {
      Roles.builder.ILT.initILTTable();
    },

    initILTTable : function() {
      /*
      |--------------------------------------------------------------------------
      | SETUP ILT COURSES
      |--------------------------------------------------------------------------
       */

      dataTable.tables['DT-add-ILTs-to-role'] = {
        tbl : $('#DT-add-ILTs-to-role').dataTable({
          filter : false,
          paging : false,
          columnDefs : [
            {
              orderable : false,
              targets : 0,
            },
          ],
        }),
      };

      /*
      |--------------------------------------------------------------------------
      | ADDING ILT COURSES TO ROLE
      |--------------------------------------------------------------------------
       */
    },

    pool : {
      show : function() {
        //Roles.builder.ILT.pool.show
        Roles.builder.show_POOL (
          Roles.builder.ILT,
          'Competencies',
          '/admin/AJAX-roles/show-ilt-pool'
        );
      },

      setBtns : function() {
        Roles.builder.setBtns_POOL (
          'DT-roles-ILT-pool',
          '.add-ilt-btn',
          '.remove-ilt-btn',
          Roles.builder.ILT,
          'data-ilt-id'
        );
      },

      refreshEvents : function () {
        Roles.builder.refreshEvents_POOL (
          '.add-ilt-btn',
          '.remove-ilt-btn',
          Roles.builder.ILT,
          'data-ilt-id'
        );
      },
    },

    add : function ( id ) {
      Roles.builder.add_POOL (
        id,
        'DT-roles-ILT-pool',
        'DT-add-ILTs-to-role',
        '.add-ilt-btn',
        '.remove-ilt-btn',
        'remove-added-ilt-btn',
        Roles.builder.ILT,
        'data-ilt-id',
        'Competency'
      );
    },

    remove : function ( id ) {
      Roles.builder.remove_POOL (
        id,
        'DT-roles-ILT-pool',
        'DT-add-ILTs-to-role',
        '.add-ilt-btn',
        '.remove-ilt-btn',
        Roles.builder.ILT,
        'data-ilt-id',
        'Competency'
      );
    },
  },

  SUMMARY : {
    init : function() {
      Roles.builder.SUMMARY.initSUMMARYTables();
    },

    initSUMMARYTables : function() {
      /*
      |--------------------------------------------------------------------------
      | SETUP ONLINE LEARNING COURSES
      |--------------------------------------------------------------------------
       */

      dataTable.tables['DT-add-OL-to-role-summary'] = {
        tbl : $('#DT-add-OL-to-role-summary').dataTable({
          filter : false,
          paging : false,
          info   : false,
          columnDefs : [

          ],
        }),
      };

      /*
      |--------------------------------------------------------------------------
      | SETUP OCC COMP EXERCISES
      |--------------------------------------------------------------------------
       */

      dataTable.tables['DT-add-oc-to-role-summary'] = {
        tbl : $('#DT-add-oc-to-role-summary').dataTable({
          filter : false,
          paging : false,
          info   : false,
          columnDefs : [

          ],
        }),
      };

      /*
      |--------------------------------------------------------------------------
      | SETUP ILT COURSES
      |--------------------------------------------------------------------------
       */

      dataTable.tables['DT-add-ilt-to-role-summary'] = {
        tbl : $('#DT-add-ilt-to-role-summary').dataTable({
          filter : false,
          paging : false,
          info   : false,
          columnDefs : [

          ],
        }),
      };
    },
  },
};
