var Users = {
    search: {
        display: function(users) {
            var user_results_src = $('#user-results-tpl').html();
            var user_results_tpl = Handlebars.compile(user_results_src);
            var user_results_htm = user_results_tpl(users);

            $('#user-search-results').html(user_results_htm);
        }
    },
    showAdd: function() {
        popup.generatePopup({
            title: 'Adding User Record',
            method: 'ajax',
            ajaxUrl: '/AJAX_Cms/add-record/user',
            maxWidth: 700,
            success: function() {
                cms.reinitDateTimePickers({
                    dateSelectors: ['.dt-picker',],
                    timeSelectors: ['.tm-picker',],
                });
            },
        });
    },

    view: function(id) {
        popup.generatePopup({
            title: 'Viewing User Record',
            method: 'ajax',
            ajaxUrl: '/AJAX_Cms/view-record/user/' + id,
        });
    },

    edit: function(id) {
        popup.generatePopup({
            title: 'Editing User Record',
            method: 'ajax',
            ajaxUrl: '/AJAX_Cms/edit-record/user/' + id,
            success: function() {
                cms.reinitDateTimePickers({
                    dateSelectors: ['.dt-picker',],
                    timeSelectors: ['.tm-picker',],
                });
            },
        });
    },

    delete: function(id) {
        popup.generatePopup({
            title: 'Deleting User Record',
            method: 'ajax',
            ajaxUrl: '/AJAX_Cms/delete-record/user/' + id,
        });
    },

    // SHARING
    share: {
        usersBeingShared: {},
        adminsBeingSharedWith: {},
        externalRecipients :{},

        init: function() {
            Users.share.steps.init();
        },

        refreshEvents: function() {
            $('#dd-select-site')
                .off('change')
                .on ('change', function(evt) {
                    Users.share.steps.arr[1].getSiteAdminsOfSite($(this).val());
                });
        },

        sharedByMe: {
            init: function() {
                dataTable.tables['DT-users-shared-by-me-currently'] = {
                    tbl: $('#DT-users-shared-by-me-currently').dataTable({
                        language: {
                            search: 'Filter Users',
                        },
                    })
                    .on('draw.dt', function() {
                        Users.share.sharedByMe.refreshEvents();
                    }),
                };

                dataTable.tables['DT-users-shared-by-me-previously'] = {
                    tbl: $('#DT-users-shared-by-me-previously').dataTable({
                        language: {
                            search: 'Filter Users',
                        },
                    })
                    .on('draw.dt', function() {
                        Users.share.sharedByMe.refreshEvents();
                    }),
                };

                Users.share.sharedByMe.refreshEvents();
            },

            refreshEvents: function() {
                $('#DT-users-shared-by-me-currently .view-itr-btn')
                    .off('click')
                    .on ('click', function(evt) {
                        var sendData = {
                            userID: $(this).closest('tr').attr('data-user-id'),
                        };

                        $.ajax({
                            url: '/admin/AJAX-users/get-inline-itr',
                            type: 'post',
                            data: sendData,
                            dataType: 'html',
                            success: function(returnedData) {
                                // console.log(returnedData);

                                $('#itr-host').html(returnedData);
                            },
                        });

                    });

                $('#DT-users-shared-by-me-previously .view-itr-btn')
                    .off('click')
                    .on ('click', function(evt) {
                        var sendData = {
                            userID: $(this).closest('tr').attr('data-user-id'),
                        };

                        $.ajax({
                            url: '/admin/AJAX-users/get-inline-itr',
                            type: 'post',
                            data: sendData,
                            dataType: 'html',
                            success: function(returnedData) {
                                // console.log(returnedData);

                                $('#itr-host').html(returnedData);
                            },
                        });

                    });

                $('#DT-users-shared-by-me-currently .revoke-btn')
                    .off('click')
                    .on ('click', function(evt) {
                        var sendData = {
                            userSharingID: $(this).closest('tr').attr('data-id'),
                        };

                        $.ajax({
                            url: '/admin/AJAX-users/revoke',
                            type: 'post',
                            data: sendData,
                            dataType: 'json',
                            success: function(returnedData) {
                                // console.log(returnedData);

                                var cTable = dataTable.tables['DT-users-shared-by-me-currently'].tbl,
                                        pTable = dataTable.tables['DT-users-shared-by-me-previously'].tbl,

                                        cRowNode = cTable
                                                                  .api()
                                                                  .row('tr[data-id="' + returnedData.userSharing.id + '"]')
                                                                  .node();

                                        cRowData = cTable
                                                                  .api()
                                                                  .row('tr[data-id="' + returnedData.userSharing.id + '"]')
                                                                  .data();

                                var pRowNode = pTable.api().row.add([
                                    cRowData[0],
                                    cRowData[1],
                                    cRowData[2],
                                    cRowData[3],
                                    showITRBtn
                                ]).draw().node();

                                $(pRowNode).attr('data-user-id', returnedData.userSharing.shared_user);

                                cTable.api().row('tr[data-id="' + returnedData.userSharing.id + '"]').remove().draw();
                            },
                        });
                    });
            },
        },

        sharedWithMe: {
            init: function() {
                dataTable.tables['DT-users-shared-with-me'] = {
                    tbl: $('#DT-users-shared-with-me').dataTable({
                        language: {
                            search: 'Filter Users',
                        },
                    })
                    .on('draw.dt', function() {
                        Users.share.sharedWithMe.refreshEvents();
                    }),
                };

                Users.share.sharedWithMe.refreshEvents();
            },

            refreshEvents: function() {
                $('#DT-users-shared-with-me .view-itr-btn')
                    .off('click')
                    .on ('click', function(evt) {
                        var sendData = {
                            userID: $(this).closest('tr').attr('data-user-id'),
                        };

                        $.ajax({
                            url: '/admin/AJAX-users/get-inline-itr',
                            type: 'post',
                            data: sendData,
                            dataType: 'html',
                            success: function(returnedData) {
                                // console.log(returnedData);

                                $('#itr-host').html(returnedData);
                            },
                        });

                    });
            },
        },

        steps: {

            current: 0,

            init: function() {
                Users.share.steps.arr[0].init();
                Users.share.steps.arr[1].init();
                Users.share.steps.arr[2].init();

                Users.share.steps.setNextStepBtnFunction(0);
                Users.share.steps.setSubmitBtnFunction();

                Users.share.steps.hideAllBut(0);

                $('#next-step-btn').show();
                $('#back-step-btn').hide();
                $('#submit-btn').hide();

                $('.step[data-index!="0"]').hide();

                Users.share.refreshEvents();
            },

            arr: [
                ///////////////////////////////
                //          STEP 1           //
                ///////////////////////////////
                {
                    id: 'step-1',

                    nextStepFunction: function() {
                        if (dataTable.tables["DT-users-to-be-shared"].tbl.api().rows({selected:true}).count() === 0) {
                            main.flashData.show('Please Select at least 1 user to share', 'negative', -1);
                        }
                        else {
                            Users.share.steps.nextStep();

                            main.flashData.hide();
                        }
                    },

                    backStepFunction: function() {
                        Users.share.steps.backStep();
                    },

                    init: function() {
                        dataTable.tables['DT-users-to-be-shared'] = {
                            tbl: $('#DT-users-to-be-shared').dataTable({
                                select: 'multi',
                                language: {
                                    search: 'Filter Results',
                                },
                            }),
                        };

                        $('#DT-users-to-be-shared tbody').on('click', 'tr', function () {
                            var table = dataTable.tables["DT-users-to-be-shared"].tbl;

                            if ($(this).hasClass('selected') ) {
                                Users.share.usersBeingShared[$(this).attr('data-id')] = {
                                    data: table.api().row(this).data(),
                                };
                            }
                            else {
                                delete Users.share.usersBeingShared[$(this).attr('data-id')];
                            }

                            Users.share.steps.arr[0].updateUsersBeingShared();
                        });
                    },

                    onShow: function() {
                        Users.share.steps.arr[0].updateUsersBeingShared();
                    },

                    updateUsersBeingShared: function() {
                        var table = dataTable.tables["DT-users-to-be-shared"].tbl;
                        var rowNode;

                        $('#DT-users-to-be-shared tr').each(function(index, elem) {
                            $(this).removeClass('selected');
                        });

                        for (var prop in Users.share.usersBeingShared) {
                            if (Users.share.usersBeingShared.hasOwnProperty(prop) ) {
                                rowNode = table.api().row('tr[data-id="' + prop + '"]').node();
                                $(rowNode).addClass('selected');
                            }
                        }
                    },
                },
                ///////////////////////////////
                //          STEP 2           //
                ///////////////////////////////
                {
                    id: 'step-2',

                    nextStepFunction: function() {
                        Users.share.steps.nextStep();
                    },

                    backStepFunction: function() {
                        Users.share.steps.backStep();
                    },

                    init: function() {
                        dataTable.tables['DT-admins-to-share-with-search'] = {
                            tbl: $('#DT-admins-to-share-with-search').dataTable({
                                paging  : false,
                                info    : false,
                                filter  : false,
                                select  : 'multi',
                                language: {
                                    emptyTable: 'Select a ' + terms.lc('terms_tier_2_s') + ' from the dropdown above',
                                },
                            }),
                        };

                        $('#DT-admins-to-share-with-search tbody').on('click', 'tr', function () {
                            var table = dataTable.tables["DT-admins-to-share-with-search"].tbl;

                            if ($(this).hasClass('selected') ) {
                                Users.share.adminsBeingSharedWith[$(this).attr('data-id')] = {
                                    data: table.api().row(this).data(),
                                };
                            }
                            else {
                                delete Users.share.adminsBeingSharedWith[$(this).attr('data-id')];
                            }

                            Users.share.steps.arr[1].updateAdminsBeingSharedWith();
                        });

                        $('#add-external-recipient-form').submit(function(evt) {
                            evt.preventDefault();

                            if ($(this).parsley().isValid()) {
                                var deleteRecipientBtnHTML = '<button class="remove-recipient-btn lone-bank-btn btn btn-danger">' +
                                                                    '<i class="fa fa-trash"></i>' +
                                                                '</button>';

                                var table = dataTable.tables['DT-external-recipients'].tbl;

                                var rowNode = table.api().row.add([
                                    $('#external-user-first-name').val(),
                                    $('#external-user-surname').val(),
                                    $('#external-user-email-address').val(),
                                    deleteRecipientBtnHTML
                                ]).draw().node();

                                $(rowNode).attr('data-email', $('#external-user-email-address').val());

                                Users.share.externalRecipients[$('#external-user-email-address').val()] = {
                                    data: [
                                        $('#external-user-first-name').val(),
                                        $('#external-user-surname').val(),
                                        $('#external-user-email-address').val(),
                                    ],
                                };

                                Users.share.steps.arr[1].updateAdminsBeingSharedWith();

                                // - REMOVE FIELD VALUES

                                setTimeout(function() {
                                    $('#external-user-first-name').val('');
                                    $("#external-user-first-name").parsley().removeError('required');

                                    $('#external-user-surname').val('');
                                    $("#external-user-surname").parsley().removeError('required');

                                    $('#external-user-email-address').val('');
                                    $("#external-user-email-address").parsley().removeError('required');
                                }, 100);

                            }
                        });

                        dataTable.tables['DT-external-recipients'] = {
                            tbl: $('#DT-external-recipients').dataTable({
                                paging  : false,
                                info    : false,
                                filter  : false,
                            })
                            .on('draw.dt', function() {
                                Users.share.steps.arr[1].refreshTrashBtns();
                            }),
                        };

                        dataTable.tables['DT-admins-to-share-with-selected'] = {
                            tbl: $('#DT-admins-to-share-with-selected').dataTable({
                                paging  : false,
                                info    : false,
                                filter  : false,
                            })
                            .on('draw.dt', function() {
                                Users.share.steps.arr[1].refreshTrashBtns();
                            }),
                        };
                    },

                    onShow: function() {
                        Users.share.steps.arr[1].updateAdminsBeingSharedWith();

                        Users.share.steps.arr[1].refreshTrashBtns();
                    },

                    getSiteAdminsOfSite: function(siteID) {
                        var sendData = {
                            siteID: siteID,
                        };

                        $.ajax({
                            url: '/AJAX-admin/get-site-admins-of-site',
                            type: 'post',
                            data: sendData,
                            dataType: 'json',
                            success: function(returnedData) {
                                if (returnedData.success) {
                                    var i, rowNode;

                                    dataTable.tables['DT-admins-to-share-with-search'].tbl.api().clear().draw();

                                    for (i = 0; i < returnedData.users.length; i++) {
                                        var id = returnedData.users[i].user_id;
                                        var table = dataTable.tables['DT-admins-to-share-with-search'].tbl;

                                        rowNode = table.api().row.add([
                                            '',
                                            returnedData.users[i].first_name,
                                            returnedData.users[i].surname,
                                            returnedData.users[i].email,
                                        ]).draw().node();

                                        $(rowNode).attr('data-id', id);
                                        $(rowNode).addClass('multi-select-padding');
                                        $(rowNode).find('td:first-child').addClass('select-checkbox');

                                        if (Users.share.adminsBeingSharedWith[id] !== undefined) {
                                            table.api().row('tr[data-id="' + id + '"]').select();
                                        }
                                    }
                                }
                            },
                        });
                    },

                    updateAdminsBeingSharedWith: function() {
                        var data,
                                rowNode,
                                prop,
                                deleteBtnHTML = '<button class="remove-user-btn lone-bank-btn btn btn-danger">' +
                                                                    '<i class="fa fa-trash"></i>' +
                                                                '</button>',
                                externalCheck = '<i class="fa fa-check"></i>',
                                destTable = dataTable.tables['DT-admins-to-share-with-selected'].tbl;

                        destTable.api().clear().draw();

                        // - INTERNAL RECIPIENTS
                        for (prop in Users.share.adminsBeingSharedWith) {
                            if (Users.share.adminsBeingSharedWith.hasOwnProperty(prop)) {

                                data = Users.share.adminsBeingSharedWith[prop].data;

                                rowNode = destTable.api().row.add([
                                    data[1],
                                    data[2],
                                    data[3],
                                    '',
                                    deleteBtnHTML
                                ]).draw().node();

                                $(rowNode).attr('data-type', 'id');
                                $(rowNode).attr('data-id', prop);
                            }
                        }

                        // - EXTERNAL RECIPIENTS
                        for (prop in Users.share.externalRecipients) {
                            if (Users.share.externalRecipients.hasOwnProperty(prop)) {

                                data = Users.share.externalRecipients[prop].data;

                                rowNode = destTable.api().row.add([
                                    data[0],
                                    data[1],
                                    data[2],
                                    externalCheck,
                                    deleteBtnHTML
                                ]).draw().node();

                                $(rowNode).attr('data-type', 'email');
                                $(rowNode).attr('data-email', prop);

                                var externalCell = $(rowNode).find('td')[3];
                                $(externalCell).addClass('tc');
                            }
                        }
                    },

                    refreshTrashBtns: function() {
                        $('#DT-admins-to-share-with-selected .remove-user-btn')
                            .off('click')
                            .on ('click', function(evt) {
                                var type = $(this).closest('tr').attr('data-type');
                                var selectedAdminsTbl;

                                if (type === 'id') {
                                    var id = $(this).closest('tr').attr('data-id');

                                    delete Users.share.adminsBeingSharedWith[id];

                                    selectedAdminsTbl = dataTable.tables['DT-admins-to-share-with-selected'].tbl;
                                    selectedAdminsTbl.api().row('tr[data-id="' + id + '"]').remove().draw();

                                    var searchedAdminsTbl = dataTable.tables['DT-admins-to-share-with-search'].tbl;
                                    searchedAdminsTbl.api().row('tr[data-id="' + id + '"]').deselect().draw();
                                }
                                else {
                                    var email = $(this).closest('tr').attr('data-email');

                                    delete Users.share.externalRecipients[email];

                                    selectedAdminsTbl = dataTable.tables['DT-admins-to-share-with-selected'].tbl;
                                    selectedAdminsTbl.api().row('tr[data-email="' + email + '"]').remove().draw();

                                    var externalRecipientsTbl = dataTable.tables['DT-external-recipients'].tbl;
                                    externalRecipientsTbl.api().row('tr[data-email="' + email + '"]').remove().draw();
                                }
                            });

                        $('#DT-external-recipients .remove-recipient-btn')
                            .off('click')
                            .on ('click', function(evt) {
                                var email = $(this).closest('tr').attr('data-email');

                                delete Users.share.externalRecipients[email];

                                var externalRecipientsTbl = dataTable.tables['DT-external-recipients'].tbl;
                                externalRecipientsTbl.api().row('tr[data-email="' + email + '"]').remove().draw();

                                Users.share.steps.arr[1].updateAdminsBeingSharedWith();
                            });
                    },
                },
                ///////////////////////////////
                //          STEP 3           //
                ///////////////////////////////
                {
                    id: 'step-3',

                    nextStepFunction: function() {
                        Users.share.steps.nextStep();
                    },

                    backStepFunction: function() {
                        Users.share.steps.backStep();
                    },

                    init: function() {
                        dataTable.tables['DT-share-users-summary-table-1'] = {
                            tbl: $('#DT-share-users-summary-table-1').dataTable({
                                paging: false,
                                info: false,
                                filter: false,
                            }),
                        };

                        dataTable.tables['DT-share-users-summary-table-2'] = {
                            tbl: $('#DT-share-users-summary-table-2').dataTable({
                                paging: false,
                                info: false,
                                filter: false,
                            }),
                        };
                    },

                    onShow: function() {
                        Users.share.steps.arr[2].copyToSummaryTable1();
                        Users.share.steps.arr[2].copyToSummaryTable2();

                        Users.share.steps.arr[2].refreshTrashBtns();
                    },

                    copyToSummaryTable1: function() {
                        var data,
                                rowNode,
                                prop,
                                deleteBtnHTML = '<button class="remove-user-btn lone-bank-btn btn btn-danger">' +
                                                                    '<i class="fa fa-trash"></i>' +
                                                                '</button>',
                                table = dataTable.tables['DT-share-users-summary-table-1'].tbl;

                        table.api().clear().draw();

                        for (prop in Users.share.usersBeingShared) {
                            if (Users.share.usersBeingShared.hasOwnProperty(prop)) {

                                data = Users.share.usersBeingShared[prop].data;

                                rowNode = table.api().row.add([
                                    data[1],
                                    data[2],
                                    data[3],
                                    deleteBtnHTML
                                ]).draw().node();

                                $(rowNode).attr('data-id', prop);
                            }
                        }
                    },

                    copyToSummaryTable2: function() {
                        var data,
                                rowNode,
                                prop,
                                deleteBtnHTML = '<button class="remove-user-btn lone-bank-btn btn btn-danger">' +
                                                                    '<i class="fa fa-trash"></i>' +
                                                                '</button>',
                                externalCheck = '<i class="fa fa-check"></i>',
                                destTable     = dataTable.tables['DT-share-users-summary-table-2'].tbl;


                        destTable.api().clear().draw();

                        for (prop in Users.share.adminsBeingSharedWith) {
                            if (Users.share.adminsBeingSharedWith.hasOwnProperty(prop)) {

                                data = Users.share.adminsBeingSharedWith[prop].data;

                                rowNode = destTable.api().row.add([
                                    data[1],
                                    data[2],
                                    data[3],
                                    '',
                                    deleteBtnHTML
                                ]).draw().node();

                                $(rowNode).attr('data-id', prop);
                            }
                        }

                        // - EXTERNAL RECIPIENTS
                        for (prop in Users.share.externalRecipients) {
                            if (Users.share.externalRecipients.hasOwnProperty(prop)) {

                                data = Users.share.externalRecipients[prop].data;

                                rowNode = destTable.api().row.add([
                                    data[0],
                                    data[1],
                                    data[2],
                                    externalCheck,
                                    deleteBtnHTML
                                ]).draw().node();

                                $(rowNode).attr('data-type', 'email');
                                $(rowNode).attr('data-email', prop);

                                var externalCell = $(rowNode).find('td')[3];
                                $(externalCell).addClass('tc');
                            }
                        }
                    },

                    refreshTrashBtns: function() {

                        $('#DT-share-users-summary-table-1 .remove-user-btn')
                            .off('click')
                            .on ('click', function(evt) {
                                var id = $(this).closest('tr').attr('data-id');

                                delete Users.share.usersBeingShared[id];

                                Users.share.steps.arr[2].copyToSummaryTable1();
                            });

                        $('#DT-share-users-summary-table-2 .remove-user-btn')
                            .off('click')
                            .on ('click', function(evt) {
                                var id = $(this).closest('tr').attr('data-id');

                                delete Users.share.adminsBeingSharedWith[id];

                                Users.share.steps.arr[2].copyToSummaryTable2();
                            });
                    },

                },
            ],

            setSubmitBtnFunction: function() {

                $('#submit-btn')
                    .off('click')
                    .on ('click', function(evt) {

                        popup.generatePopup({
                            title: 'Please confirm the sharing of these users.',
                            method: 'ajax',
                            ajaxUrl: '/admin/AJAX-users/get-share-user-confirmation-markup',
                            width: 500,
                            height: 240,
                            success: function() {

                                $('#confirm-share-users-btn')
                                    .off('click')
                                    .on ('click', function(evt) {
                                        var sendData = {
                                            usersBeingShared     : Users.share.usersBeingShared,
                                            adminsBeingSharedWith: Users.share.adminsBeingSharedWith,
                                            externalRecipients   : JSON.stringify(Users.share.externalRecipients),
                                        };

                                        $.ajax({
                                            url: '/admin/AJAX-users/share',
                                            type: 'post',
                                            data: sendData,
                                            dataType: 'json',
                                            success: function(returnedData) {
                                                // console.log(returnedData);
                                                if (returnedData.success) {
                                                    main.flashData.show('Users shared successfully.', 'positive', 4);

                                                    setTimeout(popup.close, 4000);
                                                }
                                            },
                                        });
                                    });

                            },
                        });

                    });

            },

            setNextStepBtnFunction: function(stepIndex) {
                $('#next-step-btn')
                    .off('click')
                    .on ('click', function(evt) {
                        // console.log(stepIndex);
                        Users.share.steps.arr[stepIndex].nextStepFunction();
                    });
            },

            nextStep: function() {

                Users.share.steps.tickStep(Users.share.steps.current);

                Users.share.steps.hideStep(Users.share.steps.current);
                Users.share.steps.current++;
                Users.share.steps.showStep(Users.share.steps.current);

                Users.share.steps.stepChanged();
            },

            setBackStepBtnFunction: function(stepIndex) {
                $('#back-step-btn')
                    .off('click')
                    .on ('click', function(evt) {
                        Users.share.steps.arr[stepIndex].backStepFunction();
                    });
            },

            backStep: function() {

                Users.share.steps.untickStep(Users.share.steps.current);

                Users.share.steps.hideStep(Users.share.steps.current);
                Users.share.steps.current--;
                Users.share.steps.showStep(Users.share.steps.current);

                Users.share.steps.stepChanged();
            },

            stepChanged: function() {
                if (Users.share.steps.arr[Users.share.steps.current].onShow !== undefined) {
                    Users.share.steps.arr[Users.share.steps.current].onShow();
                }

                if (Users.share.steps.current < Users.share.steps.arr.length - 1) {
                    $('#next-step-btn').show();
                    $('#submit-btn').hide();

                    Users.share.steps.setNextStepBtnFunction(Users.share.steps.current);
                }
                else {
                    $('#next-step-btn').hide();
                    $('#submit-btn').show();
                }

                if (Users.share.steps.current > 0) {
                    $('#back-step-btn').show();

                    Users.share.steps.setBackStepBtnFunction(Users.share.steps.current);
                }
                else {
                    $('#back-step-btn').hide();
                }
            },

            hideStep: function(stepIndex) {
                $('.step[data-index="' + stepIndex + '"]').hide();
            },

            showStep: function(stepIndex) {
                $('.step[data-index="' + stepIndex + '"]').show();

                $('.step-icon[data-index="' + stepIndex + '"] .step-status').attr (
                    'src', '/assets/graphics/step-you-are-here.png'
                );

                $('.step-icon').filter(function() {
                    return $(this).attr("data-index") > stepIndex;
                })
                .find('.step-status')
                .attr (
                    'src', '/assets/graphics/step-to-do.png'
                );
            },

            // - HIDE ALL BUT STEP (N)
            hideAllBut: function(stepIndex) {
                for (var i = 0; i < Users.share.steps.arr.length; i++) {

                    if (i !== stepIndex) {
                        $('#'+Users.share.steps.arr[i].id).hide();
                    }
                }
            },

            tickStep: function(stepIndex) {
                $('.step-icon[data-index="' + stepIndex + '"] .step-status').attr (
                    'src', '/assets/graphics/step-tick.png'
                );

                if (stepIndex > 0) {
                    $('.step-icon[data-index="' + stepIndex + '"]').prev()
                        .css('border-color', '#76BC76');
                }
            },

            untickStep: function(stepIndex) {
                $('.step-icon[data-index="' + stepIndex + '"] .step-status').attr (
                    'src', '/assets/graphics/step-you-are-here.png'
                );

                if (stepIndex > 0) {
                    $('.step-icon[data-index="' + (stepIndex-1) + '"]').prev()
                        .css('border-color', '#CCC');
                }
            },

        },

    },

};
