var popup = {

    currentPopup : null,
    onClose : null,

    ready : function(evt) {
        $('.popup-close-btn').off('click');
        $('.popup-close-btn').on ('click', function(evt) {
            popup.close();
        });
    },

    close : function() {
        // - ANIMATE IT OUT
        TweenMax.to($('.popup'), 0.45, {
            top: '100%',
            onComplete: function () {
                $('.popup-content').scrollTop(0);
                $(this).parentsUntil('.popup').parent().hide();
                $('.popup-blackout').hide();
                $('.overlays').hide();

                // - EMPTY TOOLBAR
                $('.popup-toolbar')
                    .empty()
                    .css('top', less.toREM(45) )
                    .hide();

                popup.toolbar('');

                // - SHOW THE FOOTER
                $('.popup footer').show();

                $('.popup-content')
                    .css('bottom', 0)// - NB THIS USED TO BE AUTO HERE BUT WAS CAUSEIN ILEARN-126
                    .css('top', less.toREM(45) );
            },
        });

        if (popup.onClose !== null) {
            popup.onClose();
            popup.onClose = null;
        }
    },

    generatePopup : function(initObj) {
        $('.popup-flex')
            .css('display', 'flex');
            
        $('.popup')
            .css('top', '100%');

        popup.currentPopup = $('.popup');

        // - FOOTER
        if (initObj.footer !== undefined && ! initObj.footer) {
            popup.hideFooter();
        }

        // - TOOLBAR
        if (initObj.toolbar !== undefined) {
            // - SET THE CONTENT
            popup.toolbar(initObj.toolbar.content);
            // - INITIALISE ANY EVENTS
            initObj.toolbar.initFunc();
        }

        if (initObj.method === 'ajax') {
            var data =(initObj.data === undefined) ? {} : initObj.data;
            var type =(initObj.type === undefined) ? 'post' : initObj.type;

            $.ajax({
                url: initObj.ajaxUrl,
                dataType: 'html',
                data: data,
                type: type,
                success: function(content) {
                    var useMaxWidth  = false;
                    var useMaxHeight = false;
                    var fullScreen   = false;

                    var maxWidth = 600;
                    if (initObj.maxWidth !== undefined) {
                        maxWidth = initObj.maxWidth;
                    }

                    var maxHeight = 430;
                    if (initObj.maxHeight !== undefined) {
                        maxHeight = initObj.maxHeight;
                    }

                    var width = 600;
                    if (initObj.width !== undefined) {
                        width = initObj.width;
                    } else {
                        useMaxWidth = true;
                    }

                    var height = 430;
                    if (initObj.height !== undefined) {
                        height = initObj.height;
                    } else {
                        useMaxHeight = true;
                    }

                    if (initObj.options !== undefined) {
                        popup.currentPopup.options = initObj.options;
                    }

                    // - IF USING FULL SCREEN OVERRIDE ALL OTHER MODES
                    if (initObj.fullScreen !== undefined && initObj.fullScreen === true) {
                        fullScreen   = true;
                        useMaxWidth  = false;
                        useMaxHeight = false;
                    }

                    if (fullScreen) {
                        $('.popup').css('max-width', '100%');
                        $('.popup').css('width', '100%');
                        $('.popup').css('max-height', '100%');
                        $('.popup').css('height', '100%');
                    } else {
                        if (useMaxWidth) {
                            $('.popup').css('max-width', maxWidth + 'px');
                            $('.popup').css('width', '100%');
                        } else {
                            $('.popup').css('width', width + 'px');
                        }

                        if (useMaxHeight) {
                            $('.popup').css('max-height', maxHeight + 'px');
                            $('.popup').css('height', '100%');
                        } else {
                            $('.popup').css('height', height + 'px');
                        }
                    }

                    $('.popup .popup-title').html((initObj.title !== undefined) ? initObj.title : 'Alert');
                    $('.popup main').html(content);

                    $('.popup-content').scrollTop(0);
                    $('.overlays').show();
                    $('.popup-blackout').show();
                    $('.popup').show();

                    // - ANIMATE IT IN
                    TweenMax.to($('.popup'), 1, {top: 0});

                    if (initObj.success !== undefined
                        && initObj.success !== null) {
                        if (initObj.successParams !== undefined
                            && initObj.successParams !== null) {
                            initObj.success(initObj.successParams);
                        } else {
                            initObj.success();
                        } 
                    }
                }
            });
        } else {
            var useMaxWidth = false;
            var useMaxHeight = false;
            var fullScreen = false;

            var maxWidth = 600;
            if (initObj.maxWidth !== undefined) {
                maxWidth = initObj.maxWidth;
            }

            var maxHeight = 430;
            if (initObj.maxHeight !== undefined) {
                maxHeight = initObj.maxHeight;
            }

            var width = 600;
            if (initObj.width !== undefined) {
                width = initObj.width;
            } else {
                useMaxWidth = true;
            }

            var height = 430;
            if (initObj.height !== undefined) {
                height = initObj.height;
            } else {
                useMaxHeight = true;
            }

            if (initObj.options !== undefined) {
                popup.currentPopup.options = initObj.options;
            }

            // - IF USING FULL SCREEN OVERRIDE ALL OTHER MODES
            if (initObj.fullScreen !== undefined && initObj.fullScreen === true) {
                fullScreen   = true;
                useMaxWidth  = false;
                useMaxHeight = false;
            }

            if (fullScreen) {
                $('.popup').css('max-width', '100%');
                $('.popup').css('width', '100%');
                $('.popup').css('max-height', '100%');
                $('.popup').css('height', '100%');
            } else {
                if (useMaxWidth) {
                    $('.popup').css('max-width', maxWidth + 'px');
                    $('.popup').css('width', '100%');
                } else {
                    $('.popup').css('width', width + 'px');
                }

                if (useMaxHeight) {
                    $('.popup').css('max-height', maxHeight + 'px');
                    $('.popup').css('height', '100%');
                } else {
                    $('.popup').css('height', height + 'px');
                }
            }

            $('.popup .popup-title').html((initObj.title !== undefined) ? initObj.title : 'Alert');
            $('.popup main').html(initObj.content);

            $('.overlays').show();
            $('.popup-blackout').show();
            $('.popup').show();

            // - ANIMATE IT IN
            TweenMax.to($('.popup'), 1, {top: 0});

            if (initObj.success !== undefined
                && initObj.success !== null) {
                initObj.success();
            }
        }
    },

    removeContent : function() {
        $('.popup main').html('');
    },

    setWidth : function(newWidth) {
        $('.popup').css('max-width', '100%');
        $('.popup').css('width', newWidth);
    },

    setHeight : function(newHeight) {
        $('.popup').css('max-height', '100%');
        $('.popup').css('height', newHeight);
    },

    // - popuplates the toolbar with content
    toolbar : function(content) {
        if (content === '') {
            // - EMPTY TOOLBAR
            $('.popup-toolbar')
                .empty()
                .css('top', less.toREM(45) )
                .hide();

            $('.popup-content').css (
                'top', less.toREM($('.popup-toolbar').outerHeight() + 45)
            );
        } else {
            $('.popup-toolbar').empty();
            $('.popup-toolbar').append(content);

            $('.popup-toolbar').show();

            setTimeout(function() {
                $('.popup-content').css (
                    'top', less.toREM($('.popup-toolbar').outerHeight() + 45)
                );
            }, 100);
        }
    },

    footer : function(content, callback, _height) {
        var height = _height || 36;

        $('.popup-content')
            .css('bottom', height + 'px');

        $('.popup footer')
            .empty()
            .append(content)
            .css('height', height + 'px')
            .show();

        callback();
    },

    showFooter : function() {
        $('.popup footer').show();
        $('.popup footer').addClass("d-flex");
    },

    hideFooter : function() {
        $('.popup footer').hide();
        $('.popup footer').removeClass("d-flex");
    },
};

$(document).ready(function(evt) {
    popup.ready(evt);
});
