var Forms = {
    init : function() {
        Forms.placeholders.init();
    },

    placeholders : {
        init : function() {
            $(".fl-ph").each(function(index, elem) {
                var input = $(elem).find("input").first();
                var label = $(elem).find("label").first();

                $(input)
                    .off("change")
                    .on("change", function() {
                        if ($(input).val() !== "") {
                            $(label).addClass("floating");
                        } else {
                            $(label).removeClass("flaoting");
                        }
                    })
                    .off("focus")
                    .on("focus", function() {
                        $(label).addClass("floating");
                    })
                    .off("blur")
                    .on("blur", function() {
                        if ($(input).val() === "") {
                            $(label).removeClass("floating");
                        }
                    });

                if ($(input).val() !== "") {
                    $(label).addClass("floating");
                } else {
                    $(label).removeClass("flaoting");
                }
            });
        }
    }
};
