var OC = {
  attemptID : null,
  currentTab : 'tab-1',
  attachToEntryIndex : null,
  assessmentTitle : $('#assessment-title').text(),
  inlineUploads : {},

  init : function ( attemptID, learningMaterials, knowledgeCheck, practiceRuns, practicalAssessments ) {
    if ( OC.isAdmin ) {
      OC.initAdmin();
    }

    OC.attemptID = attemptID;

    if ( learningMaterials !== null ) {
      OC.learningMaterials.init ( learningMaterials );
    }

    if ( knowledgeCheck !== null ) {
      OC.knowledgeCheck.init ( knowledgeCheck );
    }

    if ( practiceRuns !== null ) {
      OC.practiceRuns.init ( practiceRuns );
    }

    if ( practicalAssessments !== null ) {
      OC.practicalAssessments.init ( practicalAssessments );
    }
    // console.log(OC.learningMaterials);
    // console.log(OC.knowledgeCheck);
    // console.log(OC.practiceRuns);
    // console.log(OC.practicalAssessments);
    $('.OC-tab-content:not(.active)').hide();
    $('.OC-tab-content.active').show();

    $('.tab-button')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        if ( $(this).hasClass('not-used') ) {
          evt.stopPropagation();
          var quickHelp = $(this).find ( '.quick-help-text' );
          $(quickHelp).css ( 'left', '-50px' );
          $(quickHelp).css ( 'top', '10px' );

          TweenMax.to ( $(quickHelp) , 0.5, {
            autoAlpha: 1,
          });

          TweenMax.to ( $(quickHelp) , 0.5, {
            autoAlpha: 0,
            delay: 3.5,
          });
        }
        else {
          OC.currentTab = $(this).attr('data-container-class');

          $('.OC-tab-content').hide();

          $('.' + OC.currentTab).show();

          $('.tab-button .section-name-status').removeClass ( 'on' );
          $('.tab-button .section-name-status').addClass ( 'off' );
          $(this).find('.section-name-status').addClass ( 'on' );
          $(this).find('.section-name-status').removeClass ( 'off' );

          switch ( OC.currentTab ) {
            case 'tab-1':
              if ( OC.isCandidate ) {
                // console.log('unlock');
              }
              else {
                // console.log('lock');
              }
              break;
            case 'tab-2':
              if ( OC.isCandidate ) {
                // console.log('unlock');
              }
              else {
                // console.log('lock');
              }

              if ( OC.knowledgeCheck.status === 'L' ) {
                $('#s2-lock-watermark').show();
              }
              else {
                $('#s2-lock-watermark').hide();
              }
              break;
            case 'tab-3':
              // - ONLY MENTOR HAS A CHANCE AT ACCESSING
              if ( OC.isMentor ) {
                // - AND CAN ONLY ACCESS IF WE'RE UP TO THIS STAGE
                if ( OC.practiceRuns.status === 'P'
                  || OC.practiceRuns.status === 'I' ) {
                  // - IF IT'S PASSED IT SHOULD BE LOCKED
                  if ( OC.practiceRuns.status !== 'P' ) {
                    OC.enableAssessmentTab ( '.practice-runs' );

                    $('.practice-runs .inline-upload').refreshInlineUploadInputHeight();
                  }
                  else {
                    OC.disableAssessmentTab ( '.practice-runs', true, true );
                  }
                }
                else {
                  OC.disableAssessmentTab ( '.practice-runs', false, false );
                  main.flashData.show ( 'Please make sure all previous sections have been completed to unlock this section.', 'negative', 8 );
                }
              }
              else {
                OC.disableAssessmentTab ( '.practice-runs', false, false );
                main.flashData.show ( 'This section must be completed by the assigned mentor.', 'negative', 8 );
              }

              if ( OC.practiceRuns.status === 'L' ) {
                $('#s3-lock-watermark').show();
              }
              else {
                $('#s3-lock-watermark').hide();
              }
              break;
            case 'tab-4':
              // - ONLY MENTOR HAS A CHANCE AT ACCESSING
              if ( OC.isAssessor ) {
                if ( OC.practicalAssessments.status !== 'P' ) {
                  // - THIS IS HERE BECAUSE IF WE UNLOCK SECTION 3 AFTER EVERYTHING'S COMPLETE WE NEED TO UNLOCK SECTION 4 AS WELL
                  $('#s4-locked').prop ( 'checked', false );
                }
                // - AND CAN ONLY ACCESS IF WE'RE UP TO THIS STAGE
                if ( OC.practicalAssessments.status === 'P'
                  || OC.practicalAssessments.status === 'I' ) {
                  // - IF IT'S PASSED IT SHOULD BE LOCKED
                  if ( OC.practicalAssessments.status !== 'P' ) {
                    // console.log('should unlock cb');
                    $('#s4-locked').prop ( 'checked', false );
                    OC.enableAssessmentTab ( '.practical-assessments' );

                    $('.practical-assessments .inline-upload').refreshInlineUploadInputHeight();
                  }
                  else {
                    OC.disableAssessmentTab ( '.practical-assessments', true, true );
                  }
                }
                else {
                  OC.disableAssessmentTab ( '.practical-assessments', false, false );
                  main.flashData.show ( 'Please make sure all previous sections have been completed to unlock this section.', 'negative', 8 );
                }
              }
              else {
                OC.disableAssessmentTab ( '.practical-assessments', false, false );
                main.flashData.show ( 'This section must be completed by the assigned assessor.', 'negative', 8 );
              }

              if ( OC.practicalAssessments.status === 'L' ) {
                $('#s4-lock-watermark').show();
              }
              else {
                $('#s4-lock-watermark').hide();
              }
              break;
            default:
              // console.log('no tab selected');
          }
        }
      });

    if ( learningMaterials !== null ) {
      $('#tab-button-1').click();
    }
    else if ( knowledgeCheck !== null ) {
      $('#tab-button-2').click();
    }
    else if ( practiceRuns !== null ) {
      $('#tab-button-3').click();
    }
    else if ( practicalAssessments !== null ) {
      $('#tab-button-4').click();
    }
  },

  initAdmin : function() {
    $('#edit-mentor-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        var td = $(this).closest('td').prev();

        var sendData = {
          institutionID : OC.instID,
          siteID : OC.siteID,
        };

        $.ajax({
          url: '/AJAX-occupational-competence/get-mentors',
          type: 'post',
          data: sendData,
          dataType: 'json',
          success: function ( returnedData ) {
            // console.log(returnedData);
            if ( returnedData.success ) {
              td
                .addClass ( 'editing' )
                .empty()
                .append (
                  $('<select></select>')
                );

              for ( var i = 0; i < returnedData.mentors.length; i++ ) {
                td.find('select')
                  .append (
                    $('<option></option>')
                      .attr ( 'value', returnedData.mentors[i].user_id )
                      .text ( returnedData.mentors[i].first_name + ' ' + returnedData.mentors[i].surname )
                  );
              }
            }
          },
        });
      });

    $('#edit-assessor-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        var td = $(this).closest('td').prev();

        var sendData = {
          institutionID : OC.instID,
          siteID : OC.siteID,
        };

        $.ajax({
          url: '/AJAX-occupational-competence/get-assessors',
          type: 'post',
          data: sendData,
          dataType: 'json',
          success: function ( returnedData ) {
            // console.log(returnedData);
            if ( returnedData.success ) {
              td
                .addClass ( 'editing' )
                .empty()
                .append (
                  $('<select></select>')
                );

              for ( var i = 0; i < returnedData.assessors.length; i++ ) {
                td.find('select')
                  .append (
                    $('<option></option>')
                      .attr ( 'value', returnedData.assessors[i].user_id )
                      .text ( returnedData.assessors[i].first_name + ' ' + returnedData.assessors[i].surname )
                  );
              }
            }
          },
        });
      });
  },

  setSectionStatus : function ( tabTypeID, status ) {

    var statusClass,
        iconClass;

    switch ( status ) {
      case 'N':
        statusClass = 'not-started';
        iconClass   = 'fa fa-minus';
        break;
      case 'L':
        statusClass = 'locked';
        iconClass   = 'fa fa-lock';
        break;
      case 'I':
        statusClass = 'incomplete';
        iconClass   = 'fa fa-ellipsis-h';
        break;
      case 'P':
        statusClass = 'passed';
        iconClass   = 'fa fa-check';
        break;
    }

    switch (tabTypeID) {
      case '1':
        OC.learningMaterials.status = status;
        break;
      case '2':
        OC.knowledgeCheck.status = status;
        break;
      case '3':
        OC.practiceRuns.status = status;
        OC.practiceRuns.data.status = status;
        break;
      case '4':
        OC.practicalAssessments.status = status;
        OC.practicalAssessments.data.status = status;
        break;
      default:console.log('no default');
    }

    $( '#tab-button-' + tabTypeID )
      .find ( '.sm-circle-icon' )
      .removeClass ( 'passed' )
      .removeClass ( 'incomplete' )
      .removeClass ( 'locked' )
      .removeClass ( 'not-started' )
      .addClass    ( statusClass );

    $( '#tab-button-' + tabTypeID )
      .find ( 'i.icon, svg.icon' )
      .removeClass ( 'fa' )
      .removeClass ( 'fa-check' )
      .removeClass ( 'fa-ellipsis-h' )
      .removeClass ( 'fa-lock' )
      .removeClass ( 'fa-minus' )
      .addClass    ( iconClass );
  },

  learningMaterials : {
    docs : null,
    currentDocument : null,
    status : null,

    init : function ( learningMaterialDocs ) {
      OC.learningMaterials.docs = learningMaterialDocs.docs;

      OC.learningMaterials.setupDocuments();
    },

    save : function() {
      // OC.learningMaterials.save()
      var docs = {},
          allRead = true;

      for ( var id in OC.learningMaterials.docs ) {
        docs[id] = {
          read : OC.learningMaterials.docs[id].read
        };

        if ( OC.learningMaterials.docs[id].read === 0 ) {
          allRead = false;
        }
      }

      var status = ( allRead ) ? 'P' : 'I';

      var sendData = {
        attemptID : OC.attemptID,
        tabTypeID : 1,
        tabData : JSON.stringify({
          docs : docs,
        }),
        status : status,
      };

      $.ajax({
        url: '/AJAX-occupational-competence/save-attempt-learning-materials',
        data: sendData,
        type: 'post',
        dataType: 'json',
        success: function ( returnedData ) {
          if ( returnedData.success ) {
            OC.setSectionStatus ( returnedData.thisSectionTabID, returnedData.thisSectionStatus );

            if ( returnedData.nextSectionTabID !== undefined ) {
              OC.setSectionStatus ( returnedData.nextSectionTabID, returnedData.nextSectionStatus );
            }
          }
        }
      });
    },

    loadHTMLDocument : function ( documentID ) {
      var formData = {
        documentID : documentID,
      };

      $.ajax({
        url: '/AJAX-occupational-competence/retrieve-document',
        data: formData,
        type: 'post',
        dataType: 'html',
        success: function ( documentHTML ) {
          $('#viewing-document').html ( documentHTML );
        }
      });
    },

    setupDocuments : function() {
      dataTable.tables.DTOCDocuments.tbl.api().rows().every ( function ( rowIdx, tableLoop, rowLoop ) {
        var td = [
          $(this.node()).find('td')[0],
          $(this.node()).find('td')[1],
          $(this.node()).find('td')[2]
        ];

        var fileName   = $(td[0]).text();
        var filePath   = $(td[2]).find ( '.file-link' ).attr ( 'href' );
        var fileType   = $(td[2]).find ( '.file-link' ).attr ( 'data-file-type' );
        var documentID = $(td[2]).find ( '.file-link' ).attr ( 'data-document-id' );

        $(this.node()).find ( '.file-link' )
          .attr ( 'href', '#' )
          .attr ( 'data-file-name', filePath )
          .removeAttr ( 'target' )
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {

            repos.accessFile ( fileName, filePath, fileType, documentID, {
              callback : function() {
                if ( OC.learningMaterials.docs[documentID].read === 1 ) {
    							$('#confirm-read-doc-' + documentID + '-btn')
                    .addClass ( 'disabled' )
                    .addClass ( 'btn-light' )
                    .removeClass ( 'btn-primary' )
                    .empty()
                    .append ([
                      $('<span></span>')
                        .text ( 'You have read this document ' ),
                      $('<i></i>')
                        .addClass ( 'fa' )
                        .addClass ( 'fa-check' ),
                    ]);
    						}
    						else {
    							$('#confirm-read-doc-' + documentID + '-btn')
                    .removeClass ( 'disabled' )
                    .removeClass ( 'btn-light' )
                    .addClass ( 'btn-primary' );
    						}
              },
              confirmRead : true,
              confirmReadCallback : function () {
                if ( OC.learningMaterials.docs[documentID].read === 1 ) {
                  $('#confirm-read-doc-' + documentID + '-btn')
                    .off ( 'click' );
                }
                else {
                  $('#confirm-read-doc-' + documentID + '-btn')
                    .off ( 'click' )
      						  .on  ( 'click', function ( evt ) {
      								$('#confirm-read-doc-' + documentID + '-btn')
                        .addClass ( 'disabled' )
                        .addClass ( 'btn-light' )
                        .removeClass ( 'btn-primary' )
                        .empty()
      								  .append ([
                          $('<span></span>')
                            .text ( 'You have read this document ' ),
                          $('<i></i>')
                            .addClass ( 'fa' )
                            .addClass ( 'fa-check' ),
                        ]);

                      $('.file-link[data-document-id="' + documentID + '"]')
                        .closest('tr')
                        .find('.circle-icon')
                        .removeClass ( 'incomplete' )
                        .addClass ( 'passed' )
                          .find ( 'i.icon, svg.icon' )
                          .removeClass ( 'fa-ellipsis-h' )
                          .addClass ( 'fa fa-check' );

                      $('#confirm-read-doc-' + documentID + '-btn')
                        .off ( 'click' );

                      OC.learningMaterials.docs[documentID].read = 1;
                  		OC.learningMaterials.save();
      							});
                }
              }
            });
          });

      });
    },
  },// - END OF learningMaterials

  knowledgeCheck : {

    assessments : {},
    status : null,

    init : function ( knowledgeCheckData ) {
      //THIS NEEDED ?
      // console.log('knowledgeCheckData',knowledgeCheckData);
      // assessment.player.saveAttempt = OC.knowledgeCheck.saveAttempt;
    },

    saveAttempt : function() {
      var sendDataAssessment = {
        assessmentID : Assessment.player.assessmentID,
        percentageScore : Assessment.player.percentageScore,
        status : Assessment.player.status,
      };

      $.ajax({
        url: '/AJAX-assessment/saveAttempt',
        data: sendDataAssessment,
        type: 'post',
        dataType: 'text',
        success: function ( returnedData ) {

        },
      });

      var sendDataOC = {
        attemptID : OC.attemptID,
        tabTypeID : 2,
        tabData : {
          assessments : {},
        },

        status : 'I',
      };

      sendDataOC.tabData.assessments[Assessment.player.assessmentID] = {
        percentageScore : Assessment.player.percentageScore,
      };

      switch ( Assessment.player.status ) {
        case 'P':
          sendDataOC.status = 'P';
          sendDataOC.tabData.assessments[Assessment.player.assessmentID].passed = true;
          break;
        case 'F':
          sendDataOC.status = 'I';
          sendDataOC.tabData.assessments[Assessment.player.assessmentID].passed = false;
          break;
        case 'I':
          sendDataOC.status = 'I';
          sendDataOC.tabData.assessments[Assessment.player.assessmentID].passed = false;
          break;
      }

      sendDataOC.tabData = JSON.stringify ( sendDataOC.tabData );

      $.ajax({
        url: '/AJAX-occupational-competence/save-attempt-knowledge-check',
        data: sendDataOC,
        type: 'post',
        dataType: 'json',
        success: function ( returnedData ) {
          if ( returnedData.success ) {
            OC.setSectionStatus ( returnedData.thisSectionTabID, returnedData.thisSectionStatus );

            if ( returnedData.nextSectionTabID !== undefined ) {
              OC.setSectionStatus ( returnedData.nextSectionTabID, returnedData.nextSectionStatus );
            }
          }
        },
      });
    },
  },// - END OF knowledgeCheck

  practiceRuns : {

    data : null,

    containerClass : '.practice-run-container',

    removingAttachments : [],

    status : null,

    init : function ( data ) {
      // OC.practiceRuns.init()
      OC.practiceRuns.data = data;
      OC.practiceRuns.load();
    },

    load : function() {
      // OC.practiceRuns.load()
      var practiceRunsSRC = $('#practice-runs-tpl').html();
      var practiceRunsTPL = Handlebars.compile ( practiceRunsSRC );
      var practiceRunsHTM = practiceRunsTPL ( OC.practiceRuns.data );

      $('.practice-runs .assessments').html ( practiceRunsHTM );

      Table.addAnotherRowInit ( '.practice-run-container', '.practice-runs .add-another-row', '.oc-single-item:first-child', {
        blank : [
          'input[name="oc-date"]',
          'input[name="oc-phys-ref"]',
          'input[name="oc-passed"]',
          'input[name="oc-failed"]',
          'textarea[name="oc-notes"]',
          '#attachments',
        ],
        onAdd : function () {
          $('.practice-run-container .new-item .c1').html ( $('.practice-runs .oc-single-item').length );

          var index = OC.practiceRuns.data.assessments.length;
          $('.practice-run-container .new-item').attr ( 'data-index', index );
          $('.practice-run-container .new-item').find('.inline-upload')
            .attr ( 'id', 'inline-upload-pa-' + index )
            .attr ( 'data-index', index );

          OC.practiceRuns.data.assessments[index] = {
            date        : '',
            phys_ref    : '',
            notes       : '',
            status      : '',
            attachments : [],
          };

          OC.practiceRuns.refreshEvents();
        },
      });

      OC.practiceRuns.refreshEvents();
    },

    initInlineUploads : function ( _onlyNew ) {
      // OC.practiceRuns.initInlineUploads()

      //////////////////////////////////
      // - PRACTICE RUN ATTACHMENTS - //
      //////////////////////////////////

      var onlyNew = false;
      if ( _onlyNew !== undefined ) {
        onlyNew = _onlyNew;
      }

      $('.practice-runs .inline-upload').each ( function ( aIndex, aElem ) {

        var taskIndex = $(aElem).attr ( 'data-task-index' );

        var identifier = taskIndex;

        if ( $(aElem).attr ( 'data-attempt-index' ) !== undefined ) {
          var attemptIndex = $(aElem).attr ( 'data-attempt-index' );
          identifier += '-' + attemptIndex;
        }

        if ( onlyNew && OC.inlineUploads['pr-' + identifier] !== undefined ) {
          // - THIS SKIPS IF WE ALREADY HAVE INITIALISED THE UPLOADER
          return true;
        }

        // console.log(aElem);
        // console.log(identifier,OC.inlineUploads['pr-' + identifier]);
        OC.inlineUploads['pr-' + identifier] = {
          elem : $('#inline-upload-pr-' + identifier).inlineUpload({
            hostSelector   : '#inline-upload-pr-' + identifier,
            temp           : false,
            expiry         : '',
            repos          : 'oc-practice-runs-attachments',
            name           : '',
            subpath        : OC.attemptID,
            onInitComplete : function() {
              // console.log('initComplete');
              $(aElem).find('.upload_btn').hide();
              repos.setBreadcrumbLock ( 1 );
              repos.hideBreadcrumb();
            },
            onFileSelectionChange : function() {
              file_uploader.on_finish_all_added_to_db = function ( returnedData ) {
                OC.inlineUploads['pr-' + identifier].onFinishAllAddedToDB (
                  returnedData
                );
              };
            },
          }),
          onFinishAllAddedToDB : function ( returnedData ) {
            var selectedFiles = {};
            for (var i = 0; i < returnedData.files.length; i++) {
              returnedData.files[i].filename = returnedData.files[i].file_name;
              selectedFiles[returnedData.files[i].id] = returnedData.files[i];
            }

            var taskIndex = $('#inline-upload-pr-' + identifier)
                              .closest('tbody')
                              .attr ( 'data-task-index' );
            var attemptIndex = $('#inline-upload-pr-' + identifier)
                              .closest('tbody')
                              .attr ( 'data-attempt-index' );

            OC.AddAttachmentsToEntry (
              '.practice-run-container',
              selectedFiles,
              taskIndex,
              attemptIndex
            );

            OC.updateAttachmentData ( OC.practiceRuns );
          },
        };
      });
    },

    refreshEvents : function () {
      // OC.practiceRuns.refreshEvents()

      /*
      |----------------------
      | PRACTICE RUNS EVENTS
      |----------------------
       */

      $('#s3-locked').off ( 'change' );
      $('#s3-locked').on  ( 'change', function ( evt ) {
        if ( $(this).prop ( 'checked' ) ) {
          OC.disableAssessmentTab ( '.practice-runs', true, true );

          OC.practiceRuns.data.status = 'P';
          OC.practiceRuns.status = 'P';
        }
        else {
          OC.enableAssessmentTab ( '.practice-runs' );

          OC.practiceRuns.data.status = 'I';
          OC.practiceRuns.status = 'I';
        }
      });

      $('.practice-runs tbody.inactive')
        .find ( 'input, textarea, button')
        .prop ( 'disabled', true );

      $('.practice-runs .remove-assessment')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          var index = $(this).closest('tbody').attr('data-index');

          OC.practiceRuns.data.assessments.splice(index, 1);
          $(this).closest('tbody').remove();

          OC.practiceRuns.updateIndexes();
        });

      $('.practice-runs .remove-attachment')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          var docID = $(this).closest('.oc-attachment').attr('data-id');

          if ( OC.practiceRuns.removingAttachments.indexOf ( docID ) === -1 ) {
            OC.practiceRuns.removingAttachments.push (
              $(this).closest('.oc-attachment').attr('data-id')
            );
          }

          $(this).closest('.oc-attachment').remove();

          OC.updateAttachmentData ( OC.practiceRuns );
        });

      $('.practice-runs .oc-date')
        .off ( 'keyup' )
        .on  ( 'keyup', function ( evt ) {
          var taskIndex = $(this).closest('tbody.task').attr ( 'data-task-index' );
          // console.log(taskIndex);
          if ( $(this).closest('tbody').attr ( 'data-attempt-index' ) !== undefined ) {
            var attemptIndex = $(this).closest('tbody').attr ( 'data-attempt-index' );
            OC.practiceRuns.data.assessments[taskIndex].attempts[attemptIndex].date = $(this).val();
          }
          else {
            OC.practiceRuns.data.assessments[taskIndex].date = $(this).val();
          }
        })
        .off ( 'change' )
        .on  ( 'change', function ( evt ) {
          var taskIndex = $(this).closest('tbody.task').attr ( 'data-task-index' );
          // console.log(taskIndex,$(this).closest('tbody.task'));
          if ( $(this).closest('tbody').attr ( 'data-attempt-index' ) !== undefined ) {
            var attemptIndex = $(this).closest('tbody').attr ( 'data-attempt-index' );
            // console.log(attemptIndex);
            // console.log(OC.practiceRuns.data.assessments[taskIndex].attempts);
            OC.practiceRuns.data.assessments[taskIndex].attempts[attemptIndex].date = $(this).val();
          }
          else {
            OC.practiceRuns.data.assessments[taskIndex].date = $(this).val();
          }
        });

      $('.practice-runs .oc-date').datepicker();

      $('.practice-runs .oc-phys-ref')
        .off ( 'keyup' )
        .on  ( 'keyup', function ( evt ) {
          var taskIndex = $(this).closest('tbody').attr ( 'data-task-index' );

          if ( $(this).closest('tbody').attr ( 'data-attempt-index' ) !== undefined ) {
            var attemptIndex = $(this).closest('tbody').attr ( 'data-attempt-index' );
            OC.practiceRuns.data.assessments[taskIndex].attempts[attemptIndex].phys_ref = $(this).val();
          }
          else {
            OC.practiceRuns.data.assessments[taskIndex].phys_ref = $(this).val();
          }
        });

      $('.practice-runs .oc-notes')
        .off ( 'keyup' )
        .on  ( 'keyup', function ( evt ) {
          var taskIndex = $(this).closest('tbody').attr ( 'data-task-index' );

          if ( $(this).closest('tbody').attr ( 'data-attempt-index' ) !== undefined ) {
            var attemptIndex = $(this).closest('tbody').attr ( 'data-attempt-index' );
            OC.practiceRuns.data.assessments[taskIndex].attempts[attemptIndex].notes = $(this).val();
          }
          else {
            OC.practiceRuns.data.assessments[taskIndex].notes = $(this).val();
          }
        });

      $('.practice-runs .duplicate-s3-task')
        .off ( 'click' )
        .on  ( 'click', function() {
          var tbody           = $(this).closest('tbody');
          var taskIndex       = $(this).closest('tbody').attr ( 'data-task-index' );
          var attemptIndex    = Number ( $(this).closest('tbody').attr ( 'data-attempt-index' ) );
          var newAttemptIndex = attemptIndex + 1;
          // console.log('tbody', tbody);
          // console.log('taskIndex', taskIndex);
          // console.log('attemptIndex', attemptIndex);

          var clone = $(tbody).clone();
          $(clone)
            .find ( 'input, textarea' ).val('');
          $(clone)
            .find ( '.oc-attachments' ).html ( '<label>Attachments:</label>' );
          $(clone)
            .attr ( 'data-task-index', taskIndex )
            .attr ( 'data-attempt-index', newAttemptIndex )
            .find ( '.inline-upload' )
              .attr ( 'id', 'inline-upload-pr-' + taskIndex + '-' + newAttemptIndex )
              .attr ( 'data-task-index', taskIndex )
              .attr ( 'data-attempt-index', newAttemptIndex );

          $(tbody).after ( clone );

          var duplicateObj = {
            active      : true,
            attachments : [],
            date        : "",
            notes       : "",
            phys_ref    : "",
          };
          // console.log(duplicateObj);
          // console.log(OC.practiceRuns.data.assessments[taskIndex]);
          OC.practiceRuns.data.assessments[taskIndex].attempts.splice(newAttemptIndex, 0, duplicateObj);
          // console.log(OC.practiceRuns.data.assessments);

          OC.practiceRuns.updateIndexes();

          // - DEACTIVATE THE TASK THAT WE'VE DUPLICATED...
          OC.practiceRuns.data.assessments[taskIndex].attempts[attemptIndex].active = false;
          // console.log($(tbody));
          // console.log($(tbody).find('tr:first-child td'));
          $(tbody).data('active', 'false');
          $(tbody).addClass ( 'inactive' );
          $(tbody)
            .find ( 'input, textarea, button')
            .prop ( 'disabled', true );
          $(tbody)
            .find ( '.uploader-row' ).remove();

          $(this).closest('tr').remove();

          OC.practiceRuns.refreshEvents();
        });

      nhRadioGroup.activate (
        '.practice-runs .nh-radio-group',
        OC.practiceRuns.updateStatusesInObj
      );

      $('#s3-save-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          var docID;

          var confirmedAttachmentsToRemove = [];

          // - GO THROUGH EACH REMOVED ATTACHMENT AND CHECK IF ANY HAVE BEEN REMOVED COMPLETELY
          for ( var i = 0; i < OC.practiceRuns.removingAttachments.length; i++ ) {
            docID = OC.practiceRuns.removingAttachments[i];

            if ( $('.practice-runs .oc-attachment[data-id="' + docID + '"]').length === 0 ) {
              confirmedAttachmentsToRemove.push ( docID );
            }
          }

          var sendData = {
            attemptID                    : OC.attemptID,
            tabData                      : JSON.stringify ( OC.practiceRuns.data ),
            status                       : OC.practiceRuns.data.status,
            confirmedAttachmentsToRemove : confirmedAttachmentsToRemove,
          };

          $.ajax({
            url: '/AJAX-occupational-competence/save-tab-3-data',
            data: sendData,
            type: 'post',
            dataType: 'text',
            success: function ( returnedData ) {
              // console.log('save-tab-3-data', returnedData);
              returnedData = JSON.parse(returnedData);
              // console.log(returnedData);

              if ( returnedData.success ) {
                main.flashData.show ( 'Data Saved Successfully', 'positive', 3 );

                OC.setSectionStatus ( returnedData.thisSectionTabID, returnedData.thisSectionStatus );
                OC.setSectionStatus ( returnedData.nextSectionTabID, returnedData.nextSectionStatus );
              }
            }
          });

        });

      OC.practiceRuns.initInlineUploads ( true );
    },

    updateIndexes : function() {
      $('.practice-runs .task').each ( function ( index, elem ) {
        $(elem).attr ( 'data-task-index', index );
        $(elem).find('.task-task-index').text ( index + 1 );
      });
    },

    updateStatusesInObj : function() {
      $('.practice-runs .task').each ( function ( itemIndex, itemElem ) {
        OC.practiceRuns.data.assessments[itemIndex].status = $(itemElem).find('.nh-radio-group').attr('data-value');

        $(itemElem).attr (
          'data-complete', $(itemElem).find('.nh-radio-group').attr('data-value')
        );
      });

      if ( $('.practice-runs').find('.task[data-complete="0"]').length > 0 ) {
        $('.practice-runs')
          .find ( '.lock-cb' )
          .prop ( 'disabled', true )
          .addClass ( 'disabled' )
          .attr ( 'title', 'All tasks must be complete to lock this part of the assessment.' );
      }
      else {
        $('.practice-runs')
          .find ( '.lock-cb' )
          .prop ( 'disabled', false )
          .removeClass ( 'disabled' )
          .attr ( 'title', '' );
      }
    },

  },// - END OF practiceRuns

  practicalAssessments : {
    containerClass : '.practical-assessments-container',

    removingAttachments : [],
    status : null,

    init : function ( data ) {
      OC.practicalAssessments.data = data;
      OC.practicalAssessments.load();
    },

    load : function() {
      var practicalAssessmentsRC = $('#practical-assessments-tpl').html();
      var practicalAssessmentsTPL = Handlebars.compile ( practicalAssessmentsRC );
      var practicalAssessmentsHTM = practicalAssessmentsTPL ( OC.practicalAssessments.data );

      $('.practical-assessments .assessments').html ( practicalAssessmentsHTM );

      Table.addAnotherRowInit ( '.practical-assessments-container', '.practical-assessments .add-another-row', '.oc-single-item:first-child', {
        blank : [
          'input[name="oc-date"]',
          'input[name="oc-phys-ref"]',
          'input[name="oc-passed"]',
          'input[name="oc-failed"]',
          'textarea[name="oc-notes"]',
          '#attachments',
        ],
        onAdd : function () {
          $('.practical-assessments-container .new-item .c1').html ( $('.practical-assessments .oc-single-item').length );

          var index = OC.practicalAssessments.data.assessments.length;
          $('.practical-assessments-container .new-item').attr ( 'data-index', index );
          $('.practical-assessments-container .new-item').find('.inline-upload')
            .attr ( 'id', 'inline-upload-pa-' + index )
            .attr ( 'data-index', index );

          OC.practicalAssessments.data.assessments[index] = {
            date : '',
            phys_ref : '',
            notes : '',
            status : '',
            attachments : [],
          };

          OC.practicalAssessments.refreshEvents();
        },
      });

      OC.practicalAssessments.refreshEvents();
    },

    initInlineUploads : function ( _onlyNew ) {
      //////////////////////////////////////////
      // - PRACTICAL ASSESSMENT ATTACHMENTS - //
      //////////////////////////////////////////

      var onlyNew = false;
      if ( _onlyNew !== undefined ) {
        onlyNew = _onlyNew;
      }

      $('.practical-assessments .inline-upload').each ( function ( aIndex, aElem ) {

        var taskIndex = $(aElem).attr ( 'data-task-index' );

        var identifier = taskIndex;

        if ( $(aElem).attr ( 'data-attempt-index' ) !== undefined ) {
          var attemptIndex = $(aElem).attr ( 'data-attempt-index' );
          identifier += '-' + attemptIndex;
        }

        if ( onlyNew && OC.inlineUploads['pa-' + identifier] !== undefined ) {
          // - THIS SKIPS IF WE ALREADY HAVE INITIALISED THE UPLOADER
          return true;
        }

        // console.log(aElem);

        OC.inlineUploads['pa-' + identifier] = {
          elem : $('#inline-upload-pa-' + identifier).inlineUpload({
            hostSelector   : '#inline-upload-pa-' + identifier,
            temp           : false,
            expiry         : '',
            repos          : 'oc-practical-assessments-attachments',
            name           : '',
            subpath        : OC.attemptID,
            onInitComplete : function() {
              $(aElem).find('.upload_btn').hide();
              repos.setBreadcrumbLock ( 1 );
              repos.hideBreadcrumb();
            },
            onFileSelectionChange : function() {
              file_uploader.on_finish_all_added_to_db = function ( returnedData ) {
                OC.inlineUploads['pa-' + identifier].onFinishAllAddedToDB (
                  returnedData
                );
              };
            },
          }),
          onFinishAllAddedToDB : function ( returnedData ) {
            var selectedFiles = {};
            for (var i = 0; i < returnedData.files.length; i++) {
              returnedData.files[i].filename = returnedData.files[i].file_name;
              selectedFiles[returnedData.files[i].id] = returnedData.files[i];
            }
            var taskIndex    = $(aElem).attr ( 'data-task-index' );
            var attemptIndex = $(aElem).attr ( 'data-attempt-index' );

            OC.AddAttachmentsToEntry (
              '.practical-assessments-container',
              selectedFiles,
              taskIndex,
              attemptIndex
            );

            OC.updateAttachmentData ( OC.practicalAssessments );
          },
        };
      });
    },

    refreshEvents : function () {
      /*
      |------------------------------
      | PRACTICAL ASSESSMENTS EVENTS
      |------------------------------
       */
      $('.practical-assessments .oc-passed').off ( 'change' );
      $('.practical-assessments .oc-passed').on  ( 'change', function ( evt ) {
        $(this).parent().parent().find('.oc-failed').prop ( 'checked', !$(this).prop ( 'checked' ) );

        var index = $(this).closest('tbody').attr ( 'data-index' );
        OC.practicalAssessments.data.assessments[index].status = ( $(this).prop ( 'checked' ) ) ? 'P' : 'N';
      });

      $('.practical-assessments .oc-failed').off ( 'change' );
      $('.practical-assessments .oc-failed').on  ( 'change', function ( evt ) {
        $(this).parent().parent().find('.oc-passed').prop ( 'checked', !$(this).prop ( 'checked' ) );

        var index = $(this).closest('tbody').attr ( 'data-index' );
        OC.practicalAssessments.data.assessments[index].status = ( $(this).prop ( 'checked' ) ) ? 'N' : 'P';
      });

      $('#s4-locked').off ( 'change' );
      $('#s4-locked').on  ( 'change', function ( evt ) {
        if ( $(this).prop ( 'checked' ) ) {
          OC.disableAssessmentTab ( '.practical-assessments', true, true );

          OC.practicalAssessments.data.status = 'P';
          OC.practicalAssessments.status = 'P';
        }
        else {
          OC.enableAssessmentTab ( '.practical-assessments' );

          OC.practicalAssessments.data.status = 'L';
          OC.practicalAssessments.status = 'L';
        }
      });

      $('.practical-assessments tbody.inactive')
        .find ( 'input, textarea, button')
        .prop ( 'disabled', true );

      $('.practical-assessments .remove-assessment')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          var index = $(this).closest('tbody').attr('data-index');

          OC.practicalAssessments.data.assessments.splice(index, 1);
          $(this).closest('tbody').remove();

          OC.practicalAssessments.updateIndexes();
        });

      $('.practical-assessments .remove-attachment')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          var docID = $(this).closest('.oc-attachment').attr('data-id');

          if ( OC.practicalAssessments.removingAttachments.indexOf ( docID ) === -1 ) {
            OC.practicalAssessments.removingAttachments.push (
              $(this).closest('.oc-attachment').attr('data-id')
            );
          }

          $(this).closest('.oc-attachment').remove();

          OC.updateAttachmentData ( OC.practicalAssessments );
        });

      $('.practical-assessments .oc-date')
        .off ( 'keyup' )
        .on  ( 'keyup', function ( evt ) {
          var taskIndex = $(this).closest('tbody').attr ( 'data-task-index' );

          if ( $(this).closest('tbody').attr ( 'data-attempt-index' ) !== undefined ) {
            var attemptIndex = $(this).closest('tbody').attr ( 'data-attempt-index' );
            OC.practicalAssessments.data.assessments[taskIndex].attempts[attemptIndex].date = $(this).val();
          }
          else {
            OC.practicalAssessments.data.assessments[taskIndex].date = $(this).val();
          }
        })
        .off ( 'change' )
        .on  ( 'change', function ( evt ) {
          var taskIndex = $(this).closest('tbody').attr ( 'data-task-index' );

          if ( $(this).closest('tbody').attr ( 'data-attempt-index' ) !== undefined ) {
            var attemptIndex = $(this).closest('tbody').attr ( 'data-attempt-index' );
            OC.practicalAssessments.data.assessments[taskIndex].attempts[attemptIndex].date = $(this).val();
          }
          else {
            OC.practicalAssessments.data.assessments[taskIndex].date = $(this).val();
          }
        });

      $('.practical-assessments .oc-date').datepicker();

      $('.practical-assessments .oc-phys-ref')
        .off ( 'keyup' )
        .on  ( 'keyup', function ( evt ) {
          var taskIndex = $(this).closest('tbody').attr ( 'data-task-index' );

          if ( $(this).closest('tbody').attr ( 'data-attempt-index' ) !== undefined ) {
            var attemptIndex = $(this).closest('tbody').attr ( 'data-attempt-index' );
            OC.practicalAssessments.data.assessments[taskIndex].attempts[attemptIndex].phys_ref = $(this).val();
          }
          else {
            OC.practicalAssessments.data.assessments[taskIndex].phys_ref = $(this).val();
          }
        });

      $('.practical-assessments .oc-notes')
        .off ( 'keyup' )
        .on  ( 'keyup', function ( evt ) {
          var taskIndex = $(this).closest('tbody').attr ( 'data-task-index' );

          if ( $(this).closest('tbody').attr ( 'data-attempt-index' ) !== undefined ) {
            var attemptIndex = $(this).closest('tbody').attr ( 'data-attempt-index' );
            OC.practicalAssessments.data.assessments[taskIndex].attempts[attemptIndex].notes = $(this).val();
          }
          else {
            OC.practicalAssessments.data.assessments[taskIndex].notes = $(this).val();
          }
        });

    $('.practical-assessments .duplicate-s4-task')
      .off ( 'click' )
      .on  ( 'click', function() {
        var tbody           = $(this).closest('tbody');
        var taskIndex       = $(this).closest('tbody').attr ( 'data-task-index' );
        var attemptIndex    = Number ( $(this).closest('tbody').attr ( 'data-attempt-index' ) );
        var newAttemptIndex = attemptIndex + 1;
        // console.log('tbody', tbody);
        // console.log('taskIndex', taskIndex);
        // console.log('attemptIndex', attemptIndex);

        var clone = $(tbody).clone();
        $(clone)
          .find ( 'input, textarea' ).val('');
        $(clone)
          .find ( '.oc-attachments' ).html ( '<label>Attachments:</label>' );
        $(clone)
          .attr ( 'data-task-index', taskIndex )
          .attr ( 'data-attempt-index', newAttemptIndex )
          .find ( '.inline-upload' )
            .attr ( 'id', 'inline-upload-pa-' + taskIndex + '-' + newAttemptIndex )
            .attr ( 'data-task-index', taskIndex )
            .attr ( 'data-attempt-index', newAttemptIndex );

        $(tbody).after ( clone );

        var duplicateObj = {
          active      : true,
          attachments : [],
          date        : "",
          notes       : "",
          phys_ref    : "",
        };
        // console.log(duplicateObj);
        // console.log(OC.practicalAssessments.data.assessments[taskIndex]);
        OC.practicalAssessments.data.assessments[taskIndex].attempts.splice(newAttemptIndex, 0, duplicateObj);
        // console.log(OC.practicalAssessments.data.assessments);

        OC.practicalAssessments.updateIndexes();

        // - DEACTIVATE THE TASK THAT WE'VE DUPLICATED...
        OC.practicalAssessments.data.assessments[taskIndex].attempts[attemptIndex].active = false;
        // console.log($(tbody));
        // console.log($(tbody).find('tr:first-child td'));
        $(tbody).data('active', 'false');
        $(tbody).addClass ( 'inactive' );
        $(tbody)
          .find ( 'input, textarea, button')
          .prop ( 'disabled', true );
        $(tbody)
          .find ( '.uploader-row' ).remove();

        $(this).closest('tr').remove();

        OC.practicalAssessments.refreshEvents();
      });

      nhRadioGroup.activate (
        '.practical-assessments .nh-radio-group',
        OC.practicalAssessments.updateStatusesInObj
      );

      $('#s4-save-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          var docID;

          var confirmedAttachmentsToRemove = [];

          // - GO THROUGH EACH REMOVED ATTACHMENT AND CHECK IF ANY HAVE BEEN REMOVED COMPLETELY
          for ( var i = 0; i < OC.practicalAssessments.removingAttachments.length; i++ ) {
            docID = OC.practicalAssessments.removingAttachments[i];

            if ( $('.practical-assessments .oc-attachment[data-id="' + docID + '"]').length === 0 ) {
              confirmedAttachmentsToRemove.push ( docID );
            }
          }

          var sendData = {
            attemptID                    : OC.attemptID,
            tabData                      : JSON.stringify ( OC.practicalAssessments.data ),
            status                       : OC.practicalAssessments.data.status,
            confirmedAttachmentsToRemove : confirmedAttachmentsToRemove,
          };

          // console.log('s4-save-btn sendData', sendData);
          // console.log('s4-save-btn OC.practicalAssessments.data', OC.practicalAssessments.data);

          $.ajax({
            url: '/AJAX-occupational-competence/save-tab-4-data',
            data: sendData,
            type: 'post',
            dataType: 'json',
            success: function ( returnedData ) {
              if ( returnedData.success ) {
                main.flashData.show ( 'Data Saved Successfully', 'positive', 3 );

                if ( OC.practicalAssessments.data.status === 'P' ) {
                  OC.setSectionStatus ( returnedData.thisSectionTabID, returnedData.thisSectionStatus );
                }
              }
            }
          });
        });

      OC.practicalAssessments.initInlineUploads ( true );
    },

    updateIndexes : function() {
      $('.practical-assessments .oc-single-item').each ( function ( index, elem ) {
        $(elem).attr ( 'data-index', index );
        $(elem).find('.task-index').text ( index + 1 );
      });
    },

    updateStatusesInObj : function() {
      $('.practical-assessments .task').each ( function ( itemIndex, itemElem ) {
        OC.practicalAssessments.data.assessments[itemIndex].status = $(itemElem).find('.nh-radio-group').attr('data-value');

        $(itemElem).attr (
          'data-complete', $(itemElem).find('.nh-radio-group').attr('data-value')
        );
      });

      if ( $('.practical-assessments').find('.task[data-complete="0"]').length > 0 ) {
        $('.practical-assessments')
          .find ( '.lock-cb' )
          .prop ( 'disabled', true )
          .addClass ( 'disabled' )
          .attr ( 'title', 'All tasks must be complete to lock this part of the assessment.' );
      }
      else {
        $('.practical-assessments')
          .find ( '.lock-cb' )
          .prop ( 'disabled', false )
          .removeClass ( 'disabled' )
          .attr ( 'title', '' );
      }
    },
  },// - END OF practicalAssessments

  disableAssessmentTab : function ( tableSelector, keepLockCBActive, keepSaveActive ) {
    // - DISABLE THE ELEMENTS
    $(tableSelector)
      .find ( 'input, textarea, button' )
      .prop ( 'disabled', true );

    // - STYLE THEM DISABLED
    $(tableSelector)
      .find ( 'button.btn-primary' )
      .removeClass ( 'btn-primary' )
      .addClass ( 'btn-light' )
      .addClass ( 'disabled' );

    $(tableSelector)
      .find ( '.oc-attachments' )
      .addClass ( 'oc-attachments-disabled' );

    // - HIDE THE REMOVE ASSESSMENT BUTTONS
    $(tableSelector)
      .find ( '.remove-assessment' )
      .css ( 'visibility', 'hidden' );

    $(tableSelector)
      .find ( '.add-another-assessment' )
      .css ( 'display', 'none' );

    if ( keepLockCBActive ) {
      $(tableSelector)
        .find ( '.lock-cb' )
        .prop ( 'disabled', false )
        .removeClass ( 'disabled' );
    }

    if ( keepSaveActive ) {
      $(tableSelector)
        .find ( '.save-tab-btn' )
        .prop ( 'disabled', false )
        .removeClass ( 'btn-light' )
        .removeClass ( 'disabled' )
        .addClass ( 'btn-primary' );
    }
  },

  enableAssessmentTab : function ( tableSelector ) {
    // console.log('enabled', tableSelector);
    // - ENABLE THE ELEMENTS
    $(tableSelector)
      .find ( 'input, textarea, button' )
      .prop ( 'disabled', false );

    // - STYLE THEM ENABLED
    $(tableSelector)
      .find ( 'button.btn-light' )
      .removeClass ( 'btn-light' )
      .addClass ( 'btn-primary' )
      .removeClass ( 'disabled' );
    // console.log($(tableSelector).find ( '.oc-attachments' ));
    $(tableSelector)
      .find ( '.oc-attachments' )
      .removeClass ( 'oc-attachments-disabled' );

    // - HIDE THE REMOVE ASSESSMENT BUTTONS
    $(tableSelector)
      .find ( '.remove-assessment' )
      .css ( 'visibility', 'visible' );

    $(tableSelector)
      .find ( '.add-another-assessment' )
      .css ( 'display', 'table-cell' );

    // - WHEN ENABLING INPUTS WE NEED TO MAKE SURE THE FORM
    // - IS COMPLETE TO ACTIVATE THE COMPLETE CHECKBOX
    if ( $(tableSelector).find('.task[data-complete="0"]').length > 0 ) {
      $(tableSelector)
        .find ( '.lock-cb' )
        .prop ( 'disabled', true )
        .addClass ( 'disabled' )
        .attr ( 'title', 'All tasks must be complete to lock this part of the assessment.' );
    }
    else {
      $(tableSelector)
        .find ( '.lock-cb' )
        .prop ( 'disabled', false )
        .removeClass ( 'disabled' )
        .attr ( 'title', '' );
    }
  },

  /**
   * [AddAttachmentsToEntry adds attachments to the task in tabs 3 and 4]
   * @param {[string]} tableSelector [the class for the table e.g. .practice-runs-container]
   * @param {[object]} selectedFiles [the files that have been selected]
   * @param {[int]}    taskIndex     [tasks are ordered, each has an incremental index]
   * @param {[int]}    _attemptIndex [only used for specific task]
   */
  AddAttachmentsToEntry : function ( tableSelector, selectedFiles, taskIndex, _attemptIndex ) {('AddAttachmentsToEntry');
    var attachmentsSelector, attachmentSelector;
    var isSpecificTask = $(tableSelector)
                           .find('.task[data-task-index="' + taskIndex + '"]')
                           .hasClass('specific-task');

    if ( isSpecificTask ) {

      attachmentsSelector = '.oc-single-item' +
                            '[data-task-index="' + taskIndex + '"]' +
                            '[data-attempt-index="' + _attemptIndex + '"] ' +
                            '#attachments';

      // - NOW WORKING ON A "CHECKED ITEMS ARE IN UNCHECKED ITEMS ARE NOT" RATHER THAN ADDING
      // - SO WE NEED TO EMPTY IT FIRST AND THEN ANALYSE WHAT'S TICKED
      // $(tableSelector).find(attachmentsSelector).empty();

      for ( var documentID in selectedFiles ) {

        attachmentSelector = '.oc-attachment[data-id="' + documentID + '"]';

        if ( $(tableSelector).find(attachmentsSelector).find(attachmentSelector).length === 0 ) {
          $(tableSelector).find(attachmentsSelector).append(
            $('<div></div>')
              .addClass ( 'oc-attachment' )
              .attr ( 'data-id', documentID )
              .attr ( 'data-path', selectedFiles[documentID].path )
              .append(
                $('<a></a>')
                  .attr ( 'href', '/uploads/' + main.getSub() + '/' + selectedFiles[documentID].path )
                  .attr ( 'target', '_blank' )
                  .text ( selectedFiles[documentID].filename )
              )
              .append(
                $('<button></button>')
                  .addClass ( 'remove-attachment' )
                  .append(
                    $('<i></i>')
                      .addClass ( 'fa' )
                      .addClass ( 'fa-times' )
                  )
              )
          );
        }
        else {
          main.flashData.show('This file is already attached to this task.', 'negative', 3);
        }
      }
    }
    else {
      attachmentsSelector = '.oc-single-item' +
                            '[data-task-index="' + taskIndex + '"] ' +
                            '#attachments';

      // - NOW WORKING ON A "CHECKED ITEMS ARE IN UNCHECKED ITEMS ARE NOT" RATHER THAN ADDING
      // - SO WE NEED TO EMPTY IT FIRST AND THEN ANALYSE WHAT'S TICKED
      // $(tableSelector).find(attachmentsSelector).empty();

      for ( var documentID in selectedFiles ) {

        attachmentSelector = '.oc-attachment[data-id="' + documentID + '"]';

        if ( $(tableSelector).find(attachmentsSelector).find(attachmentSelector).length === 0 ) {

          $(tableSelector).find(attachmentsSelector).append(
            $('<div></div>')
              .addClass ( 'oc-attachment' )
              .attr ( 'data-id', documentID )
              .attr ( 'data-path', selectedFiles[documentID].path )
              .append(
                $('<a></a>')
                  .attr ( 'href', '/uploads/' + main.getSub() + '/' + selectedFiles[documentID].path )
                  .attr ( 'target', '_blank' )
                  .text ( selectedFiles[documentID].filename )
              )
              .append(
                $('<button></button>')
                  .addClass ( 'remove-attachment' )
                  .append(
                    $('<i></i>')
                      .addClass ( 'fa' )
                      .addClass ( 'fa-times' )
                  )
              )
          );
        }
        else {
          main.flashData.show('This file is already attached to this task.', 'negative', 3);
        }
      }
    }
  },

  updateAttachmentData : function ( sectionObj ) {
    // console.log('sectionObj',sectionObj);
    // console.log('sectionObj.containerClass', sectionObj.containerClass);
    $(sectionObj.containerClass)
      .find('.oc-single-item')
        .each ( function ( entryIndex, entryElem ) {
          // console.log ( entryIndex, entryElem );
          var taskIndex    = $(entryElem).attr ( 'data-task-index' );
          // console.log ( sectionObj.data.assessments[taskIndex] );
          if ( sectionObj.data.assessments[taskIndex].type === 'specific' ) {
            var attemptIndex = $(entryElem).attr ( 'data-attempt-index' );
            // console.log ( taskIndex, attemptIndex );
            // console.log('sectionObj.data.assessments[taskIndex].attempts[attemptIndex]');
            // console.log(sectionObj.data.assessments[taskIndex].attempts[attemptIndex]);
            sectionObj.data.assessments[taskIndex].attempts[attemptIndex].attachments = [];

            $(entryElem).find('.oc-attachment').each ( function ( attachmentIndex, attachmentElem ) {
              sectionObj.data.assessments[taskIndex].attempts[attemptIndex].attachments.push({
                id : $(attachmentElem).attr ( 'data-id' ),
                filename : $(attachmentElem).find('a').text(),
                path : $(attachmentElem).attr ( 'data-path' ),
              });

              // console.log($(attachmentElem).find('a').text());
            });
          }
          else {
            sectionObj.data.assessments[taskIndex].attachments = [];

            $(entryElem).find('.oc-attachment').each ( function ( attachmentIndex, attachmentElem ) {
              sectionObj.data.assessments[taskIndex].attachments.push({
                id : $(attachmentElem).attr ( 'data-id' ),
                filename : $(attachmentElem).find('a').text(),
                path : $(attachmentElem).attr ( 'data-path' ),
              });

              // console.log($(attachmentElem).find('a').text());
            });
          }
    });

    sectionObj.refreshEvents();

    // console.log('a',sectionObj.data);
  },

  enrolment : {
    init : function() {
      OC.enrolment.refreshEvents();
    },

    refreshEvents : function() {
      $('#select-mentor-assessor-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {
          popup.generatePopup({
            title: 'Select a ' + terms.mentor + ' and an Assessor',
            method: 'ajax',
            ajaxUrl: '/AJAX-occupational-competence/select-buddy-assessor-popup',
            maxWidth: 1000,
            maxHeight: 600,
            success: function(returnedData) {
              // - ADD AN ACTION BUTTON TO THE POPUP
              // console.log(returnedData);
            },
          });
        });
    },

    selectMentorsAssessors : {
      mentorID : null,
      mentor : null,
      assessorID : null,
      assessor : null,

      init : function() {
        OC.enrolment.selectMentorsAssessors.refreshEvents();
      },

      refreshEvents : function() {
        $('.select-as-mentor-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            OC.enrolment.selectMentorsAssessors.mentorID = Number ( $(this).parent().parent().parent().attr('data-id') );
            OC.enrolment.selectMentorsAssessors.mentor = {
              firstName : $(this).parent().parent().parent().find('td.first-name').html(),
              surname   : $(this).parent().parent().parent().find('td.surname').html(),
            };

            $('.selected-mentor-name').html (
              OC.enrolment.selectMentorsAssessors.mentor.firstName + ' ' +
              OC.enrolment.selectMentorsAssessors.mentor.surname
            );
          });

        $('.select-as-assessor-btn')
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {
            OC.enrolment.selectMentorsAssessors.assessorID = Number ( $(this).parent().parent().parent().attr('data-id') );
            OC.enrolment.selectMentorsAssessors.assessor = {
              firstName : $(this).parent().parent().parent().find('td.first-name').html(),
              surname   : $(this).parent().parent().parent().find('td.surname').html(),
            };

            $('.selected-assessor-name').html (
              OC.enrolment.selectMentorsAssessors.assessor.firstName + ' ' +
              OC.enrolment.selectMentorsAssessors.assessor.surname
            );
          });
      },
    },
  },

  getHistory : function ( exLocUserID, onSuccess ) {
    var sendData = {
      exLocUserID : exLocUserID,
    };

    $.ajax({
      url: '/AJAX-occupational-competence/get-history',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        onSuccess ( returnedData );
      },
    });
  },

};
