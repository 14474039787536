var Messenger = {

  recipients : {
    to  : {
      open  : false,
      users : {},
    },
    cc  : {
      open  : false,
      users : {},
    },
    bcc : {
      open  : false,
      users : {},
    },
  },

  searchPadding : [10, 0, 10, 0],

  init : function () {
    Messenger.initEvents();
    Messenger.getInteractableUsers();
  },

  initEvents : function() {
    $('#new-message-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
      TweenMax.to ( $('#form-new-message'), 0.5, {top:'36px'} );
    });

    $('#tf-to i.plus-minus')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
      if ( Messenger.recipients.to.open ) {
        Messenger.hideAddRecipient ( 'to' );
      }
      else {
        Messenger.showAddRecipient ( 'to' );
      }
    });

    $('#tf-cc i.plus-minus')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
      if ( Messenger.recipients.cc.open ) {
        Messenger.hideAddRecipient ( 'cc' );
      }
      else {
        Messenger.showAddRecipient ( 'cc' );
      }
    });

    $('#tf-bcc i.plus-minus')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
      if ( Messenger.recipients.bcc.open ) {
        Messenger.hideAddRecipient ( 'bcc' );
      }
      else {
        Messenger.showAddRecipient ( 'bcc' );
      }
    });

    $('.add-recipient-search button.btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
      var recipientType = $(evt.currentTarget).parent().attr ( 'data-recipient-type' );

      $('#' + recipientType + '-add-recipient-search')
        .find ( '.search-results option:selected')
        .each ( function ( index, elem ) {
          if ( Messenger.recipients[recipientType].users[$(elem).attr ( 'value' )] === undefined ) {
            Messenger.recipients[recipientType].users[$(elem).attr ( 'value' )] = $(elem).text();
          }
      });

      // console.log(Messenger.recipients[recipientType].users);
      Messenger.renderRecipients ( recipientType );

      return false;
    });
  },

  renderRecipients : function ( recipientType ) {
    $('#tf-' + recipientType + ' .recipients').empty();

    for ( var userID in Messenger.recipients[recipientType].users ) {
      var fullName = Messenger.recipients[recipientType].users[userID].split ( ' - ' )[0];

      $('#tf-' + recipientType + ' .recipients')
        .append (
          $('<div></div>')
            .attr ( 'data-user-id', userID )
            .attr ( 'data-recipient-type', recipientType )
            .addClass ( 'recipient' )
            .append (
              $('<span></span>')
                .addClass ( 'recipient-full-name' )
                .text ( fullName )
            )
            .append (
              $('<a></a>')
                .attr ( 'href', '#' )
                .addClass ( 'remove-recipient-btn' )
                .append (
                  $('<i></i>')
                    .addClass ( 'fa fa-times' )
                )
            )
        );

    }

    $('.remove-recipient-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        var recipientType = $(this).parent().attr ( 'data-recipient-type' );
        var userID = $(this).parent().attr ( 'data-user-id' );

        delete Messenger.recipients[recipientType].users[userID];

        $(this).parent().remove();
      });
  },

  interactableUsersLoaded : false,
  interactableUsers : null,

  getInteractableUsers : function() {
    $.ajax({
      url: '/AJAX-messenger/users-by-autofill',
      dataType: 'json',
      success: function ( returnedData ) {
        if ( returnedData.success ) {
          Messenger.interactableUsersLoaded = true;
          Messenger.interactableUsers       = returnedData.recipients;
        }
      },
    });
  },

  like : function ( searchTerm ) {
    return function(person) {
      return person.first_name.indexOf ( searchTerm ) !== -1
          || person.surname.indexOf    ( searchTerm ) !== -1
          || person.email.indexOf      ( searchTerm ) !== -1;
    }
  },

  showAddRecipient : function ( recipientType ) {
    Messenger.recipients[recipientType].open = true;
    Messenger.recipientType = recipientType;
    Messenger.hideOtherAddRecipients();

    // - CHANGE PLUS TO MINUS
    $('#tf-' + recipientType + ' i.fa-plus')
      .removeClass ( 'fa-plus' )
      .addClass ( 'fa-minus' );

    $('.add-recipient-search').css ( 'z-index', 5320 );
    $('#' + recipientType + '-add-recipient-search').css ( 'z-index', 5340 );

    $('#' + recipientType + '-add-recipient-search').css ( 'height', 'auto' );
    var hgtTarget = $('#' + recipientType + '-add-recipient-search').height() +
                    Messenger.searchPadding[0] +
                    Messenger.searchPadding[2];
    $('#' + recipientType + '-add-recipient-search').css ( 'height', 0 );

    TweenMax.to ( $('#' + recipientType + '-add-recipient-search'), 0.5, {'opacity':1} );
    TweenMax.to ( $('#' + recipientType + '-add-recipient-search'), 0.1, {'padding-top':Messenger.searchPadding[0] + 'px', delay:0.1} );
    TweenMax.to ( $('#' + recipientType + '-add-recipient-search'), 0.1, {'padding-bottom':Messenger.searchPadding[2] + 'px', delay:0.1} );
    TweenMax.to ( $('#' + recipientType + '-add-recipient-search'), 0.5, {
      'height':hgtTarget+'px',
      onComplete : function() {
        $('#' + recipientType + '-add-recipient-search')
          .find ( '.search-input' )
          .focus();
      },
      delay:0.2
    });

    $('#' + recipientType + '-add-recipient-search')
      .off ( 'keyup' )
      .on  ( 'keyup', function ( evt ) {
        if ( [16,17,18,91].indexOf ( evt.keyCode ) === -1 ) {
          // - THIS ALLOWS Cmd 91, SHIFT 16, CTRL 17 and Alt 18 to be pressed without processing
          var result = Messenger.interactableUsers.filter (
            Messenger.like (
              $('#' + recipientType + '-add-recipient-search').find ( 'input[type="text"]' ).val()
            )
          );

          $('#' + recipientType + '-add-recipient-search')
            .find ( '.search-results' )
            .empty();

          for ( var i = 0; i < result.length; i++ ) {
            $('#' + recipientType + '-add-recipient-search')
              .find ( '.search-results' )
              .append (
                $('<option></option>')
                  .attr ( 'value', result[i].id )
                  .html ( result[i].first_name + ' ' + result[i].surname + ' - ' + result[i].email )
              );
          }
        }
      })
  },

  hideAddRecipient : function ( recipientType ) {
    //Messenger.recipientType = null;

    Messenger.recipients[recipientType].open = false;

    // - CHANGE MINUS TO PLUS
    $('#tf-' + recipientType + ' i.fa-minus')
      .removeClass ( 'fa-minus' )
      .addClass ( 'fa-plus' );

    TweenMax.to ( $('#' + recipientType + '-add-recipient-search'), 0.5, {'height':0, delay:0.1} );
    TweenMax.to ( $('#' + recipientType + '-add-recipient-search'), 0.1, {'padding-bottom':0, delay:0.6} );
    TweenMax.to ( $('#' + recipientType + '-add-recipient-search'), 0.1, {'padding-top':0, delay:0.6} );
    TweenMax.to ( $('#' + recipientType + '-add-recipient-search'), 0.1, {'opacity':0, delay:0.7} );
  },

  hideOtherAddRecipients : function() {
    for ( var type in Messenger.recipients ) {
      // console.log(type, Messenger.recipientType);
      if ( type !== Messenger.recipientType ) {
        Messenger.hideAddRecipient ( type );
      }
    }
  },

};
