var IltCourses = {

    iltCourseID : null,
    tempImage   : null,
    courseInfoDownloads : {
        tempFolder : null,
        documents : [],
    },
    authorisedTrainerDownloads : {
        tempFolder : null,
        documents : [],
    },

    listInit : function() {
        $.fn.dataTable.ext.search.push(
            function( settings, data, dataIndex ) {
                console.log(settings, data[2], dataIndex);
                // - GETS THE PROVIDER ID BUT THIS DIDN'T WORK MORE THAN ONCE
                // console.log(settings.nTBody.rows[dataIndex].attributes[2].value);
                return data[2] === "All Sites"
                    || data[2] === $('#site-filter option:selected').text()
                    || $('#site-filter option:selected').val() === '';
            }
        );

        $('#site-filter')
            .off ( 'change' )
            .on  ( 'change', function ( evt ) {
                dataTable.tables['DT-ILT-courses-list'].tbl.api().draw();
            });
    },

    showAdd : function() {
        popup.generatePopup({
            title: 'Adding Instructor-led Training Course',
            method: 'ajax',
            ajaxUrl: '/AJAX_Cms/add-record/instructor-led-training-course',
            maxWidth: 700,
            success: function() {
                // cms.reinitDateTimePickers ({
                //   dateSelectors : ['.dt-picker',],
                //   timeSelectors : ['.tm-picker',],
                // });
            },
        });
    },

    view : function ( id ) {
        main.view ( '/admin/instructor-led-training-courses/view', id, function ( data ) {
            // console.log(data);
        });
    },

    edit : function ( id ) {
        popup.generatePopup({
            title: 'Editing Instructor-led Training Course',
            method: 'ajax',
            ajaxUrl: '/AJAX_Cms/edit-record/instructor-led-training-course/' + id,
            success: function() {
                cms.reinitDateTimePickers ({
                    dateSelectors : ['.dt-picker',],
                    timeSelectors : ['.tm-picker',],
                });
            },
        });
    },

    delete : function ( btn ) {
        var iltCourseID = btn.attr ( 'data-id' );

        popup.generatePopup({
            title: 'Confirm Action',
            method: 'ajax',
            ajaxUrl: '/AJAX-admin/show-confirm-high-level-action-with-password',
            width: 450,
            height: 200,
            success: function ( params ) {
                $('#confirm-action-with-pw-btn')
                    .off ( 'click' )
                    .on  ( 'click', function ( evt ) {
                        var sendData = {
                            confirm: $('input[name="confirm-password"]').val(),
                            targetMethod: 'delete-ilt-course',
                            params: params,
                        };

                        $.ajax({
                            url: '/AJAX-admin/confirm-high-level-action-with-password',
                            type: 'post',
                            data: sendData,
                            dataType: 'json',
                            success: function ( returnedData ) {
                                var msg, msgState;
                                // console.log(returnedData);
                                if ( returnedData.success ) {
                                    msg = "The site has been successfully archived";
                                    msgState = "positive";

                                    dataTable.tables['DT-ILT-courses-list'].tbl.api().row('[data-id="' + returnedData.iltCourseID + '"]').remove().draw();
                                }
                                else {
                                    msgState = "negative";

                                    switch ( returnedData.reason ) {
                                        case 'bad-permission':
                                            msg = "You don't possess the required permissions to perform this task";
                                            break;
                                        case 'password-incorrect':
                                            msg = "The password you entered was incorrect";
                                            break;
                                    }
                                }

                                popup.close();

                                main.flashData.show ( msg, msgState, 5 );
                            },
                            error: Errors.ajaxErrorHandler,
                        });

                        // console.log(params.iltCourseID);
                    });
            },
            successParams : {
                iltCourseID: iltCourseID,
            },
        });
    },

    getCourseInfoDownloads : function ( iltCourseID ) {
        var sendData = {
            iltCourseID : iltCourseID,
        };

        $.ajax({
            url: '/admin/AJAX-ilt-courses/get-course-info-downloads',
            type: 'post',
            data: sendData,
            dataType: 'json',
            success: function ( returnedData ) {
                var iltDownloadsSRC = $('#ilt-courses-downloads-tpl').html(),
                    iltDownloadsTPL = Handlebars.compile ( iltDownloadsSRC ),
                    iltDownloadsHTM = iltDownloadsTPL ( returnedData );

                if ( dataTable.tables.DTIltCourseDownloads !== undefined ) {
                    dataTable.tables.DTIltCourseDownloads.tbl.api().destroy();
                }

                $('#ilt-course-downloads table tbody').html ( iltDownloadsHTM );

                dataTable.tables.DTIltCourseDownloads = {
                    tbl : $('#DT-ilt-course-downloads').dataTable({
                        columnDefs : [
                            {
                                width : 24,
                                sortable : false,
                                targets : -1,
                            },
                        ],
                        language : {
                            search : 'Filter Downloads',
                        },
                    }),
                };

                $('#ilt-course-downloads .delete-file-btn')
                    .off ( 'click' )
                    .on  ( 'click', function ( evt ) {
                        var documentID = $(this).closest ( 'tr' ).attr ( 'data-id' );

                        dataTable.tables.DTIltCourseDownloads.tbl.api().row (
                            $(this).closest ( 'tr' )
                        ).remove().draw();

                        IltCourses.deleteDocument ( documentID, IltCourses.iltCourseID );
                    });
            }
        });
    },

    getTempCourseInfoDownloads : function ( returnedData ) {
        var inArray;

        for ( var i = 0; i < returnedData.files.length; i++ ) {
            inArray = false;
            for ( var j = 0; j < IltCourses.courseInfoDownloads.documents.length; j++ ) {
                if ( IltCourses.courseInfoDownloads.documents[j].id === returnedData.files[i].id ) {
                    inArray = true;
                    break;
                }
            }

            if ( ! inArray ) {
                IltCourses.courseInfoDownloads.documents.push({
                    id : returnedData.files[i].id,
                    file_name : returnedData.files[i].file_name,
                    title : returnedData.files[i].file_name,
                    path : 'ilt-course-info-downloads/tmp/' + IltCourses.courseInfoDownloads.tempFolder + '/' + returnedData.files[i].file_name,
                });
            }
        }

        IltCourses.updateCourseInfoDownloadsField();

        IltCourses.displayTempCourseInfoDownloads ( IltCourses.courseInfoDownloads );
    },

    updateCourseInfoDownloadsField : function() {
        var output = [];

        for ( var i = 0; i < IltCourses.courseInfoDownloads.documents.length; i++ ) {
            output.push ( IltCourses.courseInfoDownloads.documents[i].id );
        }

        $('input[name="course-info-download-ids"]').val ( JSON.stringify ( output ) );
    },

    displayTempCourseInfoDownloads : function ( documentData ) {
        var iltDownloadsSRC = $('#ilt-courses-downloads-tpl').html(),
            iltDownloadsTPL = Handlebars.compile ( iltDownloadsSRC ),
            iltDownloadsHTM = iltDownloadsTPL ( documentData );

        if ( dataTable.tables.DTIltCourseDownloads !== undefined ) {
            dataTable.tables.DTIltCourseDownloads.tbl.api().destroy();
        }

        $('#ilt-course-downloads table tbody').html ( iltDownloadsHTM );

        dataTable.tables.DTIltCourseDownloads = {
            tbl : $('#DT-ilt-course-downloads').dataTable({
                columnDefs : [
                    {
                        width : 24,
                        sortable : false,
                        targets : -1,
                    },
                ],
                language : {
                    search : 'Filter Downloads',
                },
            }),
        };

        $('#ilt-course-downloads .delete-file-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
                var documentID = $(this).closest ( 'tr' ).attr ( 'data-id' );

                dataTable.tables.DTIltCourseDownloads.tbl.api().row (
                    $(this).closest ( 'tr' )
                ).remove().draw();

                IltCourses.deleteDocument ( documentID, IltCourses.iltCourseID );
            });
    },

    getAuthorisedTrainerDownloads : function ( iltCourseID ) {
        var sendData = {
            iltCourseID : iltCourseID,
        };

        $.ajax({
            url: '/admin/AJAX-ilt-courses/get-authorised-trainer-downloads',
            type: 'post',
            data: sendData,
            dataType: 'json',
            success: function ( returnedData ) {
                var iltDownloadsSRC = $('#ilt-authorised-trainer-downloads-tpl').html(),
                    iltDownloadsTPL = Handlebars.compile ( iltDownloadsSRC ),
                    iltDownloadsHTM = iltDownloadsTPL ( returnedData );

                if ( dataTable.tables.DTAuthorisedTrainerDownloads !== undefined ) {
                    dataTable.tables.DTAuthorisedTrainerDownloads.tbl.api().destroy();
                }

                $('#authorised-trainer-downloads table tbody').html ( iltDownloadsHTM );

                $('#authorised-trainer-downloads .delete-file-btn')
                    .off ( 'click' )
                    .on  ( 'click', function ( evt ) {
                        var documentID = $(this).closest ( 'tr' ).attr ( 'data-id' );

                        dataTable.tables.DTAuthorisedTrainerDownloads.tbl.api().row (
                            $(this).closest ( 'tr' )
                        ).remove().draw();

                        IltCourses.deleteDocument ( documentID, IltCourses.iltCourseID );
                    });

                dataTable.tables.DTAuthorisedTrainerDownloads = {
                    tbl : $('#DT-authorised-trainer-downloads').dataTable({
                        columnDefs : [
                            {
                                width : 24,
                                sortable : false,
                                targets : -1,
                            },
                        ],
                        language : {
                            search : 'Filter Downloads',
                        },
                    }),
                };
            }
        });
    },

    getTempAuthorisedTrainerDownloads : function ( returnedData ) {

        var inArray;

        for ( var i = 0; i < returnedData.files.length; i++ ) {
            inArray = false;
            for ( var j = 0; j < IltCourses.authorisedTrainerDownloads.documents.length; j++ ) {
                if ( IltCourses.authorisedTrainerDownloads.documents[j].id === returnedData.files[i].id ) {
                    inArray = true;
                    break;
                }
            }

            if ( ! inArray ) {
                IltCourses.authorisedTrainerDownloads.documents.push({
                    id : returnedData.files[i].id,
                    file_name : returnedData.files[i].file_name,
                    title : returnedData.files[i].file_name,
                    path : 'authorised-trainer-downloads/tmp/' + IltCourses.authorisedTrainerDownloads.tempFolder + '/' + returnedData.files[i].file_name,
                });
            }
        }

        IltCourses.updateAuthorisedTrainerDownloadsField();

        IltCourses.displayTempAuthorisedTrainerDownloads ( IltCourses.authorisedTrainerDownloads );
    },

    updateAuthorisedTrainerDownloadsField : function() {
        var output = [];

        for ( var i = 0; i < IltCourses.authorisedTrainerDownloads.documents.length; i++ ) {
            output.push ( IltCourses.authorisedTrainerDownloads.documents[i].id );
        }

        $('input[name="authorised-trainer-download-ids"]').val ( JSON.stringify ( output ) );
    },

    displayTempAuthorisedTrainerDownloads : function ( documentData ) {

        var iltDownloadsSRC = $('#ilt-authorised-trainer-downloads-tpl').html(),
            iltDownloadsTPL = Handlebars.compile ( iltDownloadsSRC ),
            iltDownloadsHTM = iltDownloadsTPL ( documentData );

        if ( dataTable.tables.DTAuthorisedTrainerDownloads !== undefined ) {
            dataTable.tables.DTAuthorisedTrainerDownloads.tbl.api().destroy();
        }

        $('#authorised-trainer-downloads table tbody').html ( iltDownloadsHTM );

        $('#authorised-trainer-downloads .delete-file-btn')
            .off ( 'click' )
            .on  ( 'click', function ( evt ) {
                var documentID = $(this).closest ( 'tr' ).attr ( 'data-id' );

                dataTable.tables.DTAuthorisedTrainerDownloads.tbl.api().row (
                    $(this).closest ( 'tr' )
                ).remove().draw();

                IltCourses.deleteDocument ( documentID, IltCourses.iltCourseID );
            });

        dataTable.tables.DTAuthorisedTrainerDownloads = {
            tbl : $('#DT-authorised-trainer-downloads').dataTable({
                columnDefs : [
                    {
                        width : 24,
                        sortable : false,
                        targets : -1,
                    },
                ],
                language : {
                    search : 'Filter Downloads',
                },
            }),
        };
    },

    refreshDownloads : function ( iltCourseID ) {
        var sendData = {
            iltCourseID : iltCourseID,
        };

        $.ajax({
            url: '/admin/AJAX-ilt-courses/get-course-info-downloads',
            type: 'post',
            data: sendData,
            dataType: 'json',
            success: function ( returnedData ) {
                dataTable.tables.DTIltCourseDownloads.tbl.api().clear().draw();
                var rowNode;
                for ( var i = 0; i < returnedData.documents.length; i++ ) {
                    rowNode = dataTable.tables.DTIltCourseDownloads.tbl.api().row.add([
                        '<a href="/uploads/' + main.getSub() + '/' + returnedData.documents[i].path + '" target="_blank">' +
                        returnedData.documents[i].title +
                        '</a>',
                        '<div class="btn-bank-host">' +
                        '<a class="delete-file-btn btn-bank btn btn-danger">' +
                        '<i class="fa fa-trash"></i>' +
                        '</a>' +
                        '</div>',
                    ]).draw().node();

                    $(rowNode).attr ( 'data-id', returnedData.documents[i].id );
                }

                $('#ilt-course-downloads .delete-file-btn')
                    .off ( 'click' )
                    .on  ( 'click', function ( evt ) {
                        var documentID = $(this).closest ( 'tr' ).attr ( 'data-id' );

                        dataTable.tables.DTIltCourseDownloads.tbl.api().row (
                            $(this).closest ( 'tr' )
                        ).remove().draw();

                        IltCourses.deleteDocument ( documentID, IltCourses.iltCourseID );
                    });
            }
        });
    },

    deleteDocument : function ( documentID, iltCourseID ) {
        repos.deleteDocument ( documentID, true );
    },

    setAwardingBodyLogo : function ( id ) {
        var sendData = {
            id : id,
        };

        $.ajax({
            url: '/AJAX-ilt-courses/set-awarding-body-logo',
            type: 'post',
            data: sendData,
            dataType: 'json',
            success: function ( returnedData ) {
                // console.log(returnedData);
            },
        });
    },

    displayTempAwardingBodyLogo : function() {
        $('#awarding-body-logo')
            .css ( 'background-image', 'url(\'/uploads/' + main.getSub() + '/' + IltCourses.tempImage.path + '\')' )
            .css ( 'background-repeat', 'no-repeat' )
            .css ( 'background-size', 'contain' )
            .css ( 'background-position', 'center center' )
            .find ( 'i' )
            .remove();

        $('input[name="awarding_body_logo_id"]').val ( IltCourses.tempImage.id );
    },

    displayAwardingBodyLogo : function ( document ) {
        $('#awarding-body-logo')
            .css ( 'background-image', 'url(\'/uploads/' + main.getSub() + '/' + document.path + '\')' )
            .css ( 'background-repeat', 'no-repeat' )
            .css ( 'background-size', 'contain' )
            .css ( 'background-position', 'center center' )
            .find ( 'i' )
            .remove();

        console.log($('input#awarding_body_logo_id'), document.id);
        $('input#awarding_body_logo_id').val ( document.id );
    },

};
