function Steps(parentObj, stepsObj, submitFunction)
{
    return {
        current : null,
        currentIndex : 0,

        stepIDs : [],

        arr : stepsObj,

        init : function()
        {
            this.setNextStepBtnFunction('step-1');
            this.setSubmitBtnFunction();

            this.hideAllBut('step-1');

            for (var id in this.arr) {
                this.stepIDs.push(id);
            }

            this.current = this.stepIDs[0];

            $('#next-step-btn').show();
            $('#back-step-btn').hide();
            $('#submit-btn').hide();
        },

        setSubmitBtnFunction : submitFunction,

        setNextStepBtnFunction : function(stepID)
        {
            var arr = this.arr;

            $('#next-step-btn')
                .off('click')
                .on('click', function(evt) {
                    arr[stepID].nextStepFunction();
                });
        },

        nextStep : function()
        {
            this.tickStep(this.current);
            this.hideStep(this.current);
            this.currentIndex++;
            this.current = this.stepIDs[this.currentIndex];
            this.showStep(this.current);
            this.stepChanged();
        },

        nextStepID : function()
        {
            return this.stepIDs[this.currentIndex+1];
        },

        markNextStepNotDone : function(next_step_id)
        {
            console.log(next_step_id);
            $('.step-icon[data-step-id="' + next_step_id + '"] .step-status')
                .removeClass("fa-circle-check fa-circle-dot")
                .addClass("fa-circle");
        },

        setBackStepBtnFunction : function(stepID)
        {
            //console.log('setBackStepBtnFunction', stepID);
            var arr = this.arr;

            $('#back-step-btn')
                .off('click')
                .on('click', function(evt) {
                     //console.log(stepID);
                     //console.log(arr[stepID]);
                    arr[stepID].backStepFunction();
                });
        },

        backStep : function()
        {
            this.untickStep(this.current);
            this.hideStep(this.current);
            this.currentIndex--;
            this.markNextStepNotDone(this.nextStepID());
            this.current = this.stepIDs[this.currentIndex];
            this.showStep(this.current);
            this.stepChanged();
        },

        getIndex : function(stepID)
        {
            for (var i = 0; i < this.stepIDs.length; i++) {
                if (this.stepIDs[i] === stepID) {
                    return i;
                }
            }
        },

        stepChanged : function()
        {
            var stepIndex = this.getIndex(this.current);

            if (this.arr[this.current].onShow !== undefined) {
                this.arr[this.current].onShow();
            }

            if (stepIndex < this.stepIDs.length - 1) {
                $('#next-step-btn').show();
                $('#submit-btn').hide();

                this.setNextStepBtnFunction(this.current);
            } else {
                $('#next-step-btn').hide();
                $('#submit-btn').show();
            }

            if (stepIndex > 0) {
                $('#back-step-btn').show();

                this.setBackStepBtnFunction(this.current);
            } else {
                $('#back-step-btn').hide();
            }
        },

        hideStep : function(stepID)
        {
            $('.step[data-step-id="' + stepID + '"]').hide();
        },

        showStep : function(stepID)
        {
            $('.step[data-step-id="' + stepID + '"]').show();

            $('.step-icon[data-step-id="' + stepID + '"] .step-status')
                .removeClass("fa-circle-check fa-circle")
                .addClass("fa-circle-dot");

            var stepIndex = OC.enrol.steps.getIndex(stepID);

            $('.step-icon')
                .filter(function() {
                    return $(this).attr("data-index") > stepIndex;
                })
                .find('.step-status')
                .attr (
                    'src', '/assets/graphics/step-to-do.png'
                );
        },

        // - HIDE ALL BUT STEP (N)
        hideAllBut : function(stepID)
        {
            for (var step in this.arr) {
                if (step !== stepID) {
                    $('#'+step).hide();
                }
            }
        },

        tickStep : function(stepIndex)
        {
            $('.step-icon[data-index="' + stepIndex + '"] .step-status')
                .removeClass("fa-circle fa-circle-dot")
                .addClass("fa-circle-check");

            if (stepIndex > 0) {
                $('.step-icon[data-index="' + stepIndex + '"]').prev()
                    .css ( 'border-color', '#76BC76' );
            }
        },

        untickStep : function(stepIndex)
        {
            $('.step-icon[data-index="' + stepIndex + '"] .step-status')
                .removeClass("fa-circle-check")
                .addClass("fa-circle-dot");

            if (stepIndex > 0) {
                $('.step-icon[data-index="' + (stepIndex-1) + '"]').prev()
                    .css('border-color', '#CCC');
            }
        },

        removeStepsNav : function()
        {
            $('.steps-nav-container').hide();
            $('#next-step-btn').hide();
            $('#submit-btn').show();
        },

    };
}
