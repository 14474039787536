var Venues = {
    add : function(data) {
        console.log(data);
        $.ajax({
            url: '/AJAX-venues/add-process',
            type: 'post',
            data: data,
            dataType: 'json',
            success: function(returnedData) {
                console.log(returnedData);
                if (returnedData.success) {
                    main.flashData.show('Venue added successfully...', 'positive', -1);
                    // console.log('Venues.onAddRefresh', Venues.onAddRefresh);
                    cms.refreshSelect(
                        "venue_id",
                        "Venues_m",
                        "getInteractableVenuesDropDown",
                        "name",
                        "id",
                        Venues.onAddRefresh,
                        [returnedData.venueID]
                    );
                }
            },
            error: function(err) {
                console.log(err);
            }
        });
        // console.log(sendData);
    },

    onAddRefresh : function() {
        $('#venue_id').select_by_value(arguments[0]);
        setTimeout(main.flashData.hide, 2500);
        setTimeout(popup.close, 3000);
    },
};
