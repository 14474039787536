Qualifications.enrol = {
  qualificationID : null,
  usersToEnrol    : {},

  init : function ( qualificationID ) {
    Qualifications.enrol.qualificationID = qualificationID;

    dataTable.tables['DT-qualification-enrolable-users'] = {
      tbl : $('#DT-qualification-enrolable-users').dataTable({
        select : 'multi',
        language : {
          search : 'Filter Users',
        },
      }),
    };

    dataTable.tables['DT-qualification-selected-users'] = {
      tbl : $('#DT-qualification-selected-users').dataTable({
        language : {
          search : 'Filter Users',
        },
      }),
    };

    dataTable.tables['DT-qualification-enrolled-users'] = {
      tbl : $('#DT-qualification-enrolled-users').dataTable({
        language : {
          search : 'Filter Users',
        },
      }),
    };

    Qualifications.enrol.refreshEvents();
  },

  refreshEvents : function() {
    $('#submit-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        var selectedUsersCount = dataTable.tables['DT-qualification-enrolable-users'].tbl.api()
                                   .rows({selected:true}).count();

        if ( selectedUsersCount === 0 ) {
          main.flashData.show ( 'Please Select at least 1 user to enrol', 'negative', -1 );
        }
        else {
          Qualifications.enrol.enrolUsers(function(returnedData) {
            if ( returnedData.success ) {
              main.flashData.show (
                'The selected users were successfully enrolled.', 'positive', 4
              );
            }
          });

          main.flashData.hide();
        }
      });

    $('#DT-qualification-enrolable-users')
      .off ( 'click' )
      .on  ('click', 'tr', function () {
        var table = dataTable.tables['DT-qualification-enrolable-users'].tbl;

        if ( $(this).hasClass ( 'selected' ) ) {
          Qualifications.enrol.usersToEnrol[$(this).attr ( 'data-user-id' )] = {
            data : table.api().row(this).data(),
          };
        }
        else {
          delete Qualifications.enrol.usersToEnrol[$(this).attr ( 'data-user-id' )];
        }

        Qualifications.enrol.updateUsersToEnrol();
        Qualifications.enrol.updateSelectedUsersTable();
      });

    $('.remove-enrolled-user-btn')
      .on ( 'click', Qualifications.enrol.removeEnrolledUserBtn_click );
  },

  enrolUsers : function ( callback ) {
    var sendData = {
      users : Qualifications.enrol.usersToEnrol,
      qualificationID : Qualifications.enrol.qualificationID,
    };

    $.ajax({
      url: '/admin/AJAX-qualifications/enrol-users',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        callback ( returnedData );
      },
    });
  },

  removeEnrolledUserBtn_click : function ( evt ) {
    Qualifications.enrol.removeEnrolledUser ( $(evt.currentTarget) );
  },

  updateUsersToEnrol : function() {
    var table = dataTable.tables['DT-qualification-enrolable-users'].tbl;
    var rowNode;

    $('#DT-qualification-enrolable-users tr').each ( function ( index, elem ) {
      $(this).removeClass('selected');
    });

    for ( var prop in Qualifications.enrol.usersToEnrol ) {
      if ( Qualifications.enrol.usersToEnrol.hasOwnProperty ( prop ) ) {
        rowNode = table.api().row('tr[data-user-id="' + prop + '"]').node();
        $(rowNode).addClass('selected');
      }
    }
  },

  updateSelectedUsersTable : function() {
    var table = dataTable.tables['DT-qualification-selected-users'].tbl;
    var userID, rowNode;

    var removeSelectedUserBtn_click = function ( evt ) {
      Qualifications.enrol.removeSelectedUser ( $(evt.currentTarget) );
    };

    table.api().clear();

    for ( userID in Qualifications.enrol.usersToEnrol ) {
      removeBtn = '<div class="btn-bank-host">' +
                    '<button class="remove-selected-user-btn btn btn-danger btn-bank">' +
                      '<i class="fa fa-trash"></i>' +
                    '</button>' +
                  '</div>';

      rowNode = table.api().row.add([
        Qualifications.enrol.usersToEnrol[userID].data[1],
        Qualifications.enrol.usersToEnrol[userID].data[2],
        Qualifications.enrol.usersToEnrol[userID].data[3],
        removeBtn,
      ]).draw().node();

      $(rowNode)
        .attr ( 'data-user-id', userID )
        .on ( 'click', removeSelectedUserBtn_click );
    }
  },

  removeSelectedUser : function ( rowToRemove ) {
    var userID = $(rowToRemove).attr('data-user-id');

    var selectedUsersTbl  = dataTable.tables['DT-qualification-selected-users'].tbl;
    var enrolableUsersTbl = dataTable.tables['DT-qualification-enrolable-users'].tbl;
    var rowNode;

    // - REMOVE THE ROW FROM SELECTED
    selectedUsersTbl.api().row('tr[data-user-id=' + userID + ']').remove().draw();

    // - UNCHECK IN ENROLABLE TABLE
    enrolableUsersTbl.api().row('tr[data-user-id=' + userID + ']').deselect().draw();

     // -  REMOVE FROM OBJECT
     delete Qualifications.enrol.usersToEnrol[userID];
  },

  // removeEnrolledUser : function ( rowToRemove ) {
  //   var userID = $(rowToRemove).closest('tr').attr('data-user-id');
  //
  //   var enrolledUsersTbl  = dataTable.tables['DT-qualification-enrolled-users'].tbl;
  //   var rowNode;
  //
  //   // - REMOVE THE ROW FROM ENROLLED
  //   enrolledUsersTbl.api().row('tr[data-user-id=' + userID + ']').remove().draw();
  //
  //   // - check with john how he wants to proceed,
  //   // - recommend, it unenrols user from the qual but not the constituent parts, needs some thought.
  //   // Qualifications.enrol.unenrolUser ( userID );
  // },
};
