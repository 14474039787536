var Permissions = {
  init : function() {
    Permissions.refreshEvents();
  },

  refreshEvents : function() {
    // - WE HAVE A SET OF TOOLTIPS FOR EACH PERMISSION ON THE SET PERMISSION PAGE
    // - THIS WORKS ON LABEL CHECKBOX CSS, NEARLY WORKED COMPLETELY BUT WE NEED TO REALLY
    // - REMOVE THE OTHER SHOWING TOOLTIPS WHENEVER WE ACCESS ONE...CUE JS WORKAROUND
    
    $('.pfd-cb')
      .off ( 'change' )
      .on  ( 'change', function ( evt ) {
        var id = $(this).attr('id');

        if ( $(this).prop ( 'checked' ) ) {
          $('.pfd-cb:not(#' + id + ')').prop('checked', false);
        }
      });
  },

  getDetailOfPermission : function ( permissionID, detailMethod, controllerUserID, permisseeID, _popupOverrideObj ) {

    var popupObj = {
      title: 'Permissions Detail',
      method: 'literal',
      content: '{returnedData}',
      maxWidth: 1000,
      maxHeight: 690,
      footer: false,
    };

    if ( _popupOverrideObj !== undefined ) {
      popupObj = _popupOverrideObj;
    }


    var sendData = {
      permissionID     : permissionID,
      controllerUserID : controllerUserID,
      permisseeID      : permisseeID,
    };

    $.ajax({
      url: '/AJAX-permission-detail/' + detailMethod,
      type: 'post',
      data: sendData,
      dataType: 'html',
      success: function ( returnedData ) {
        if ( popupObj.content === '{returnedData}' ) {
          popupObj.content = returnedData;
        }

        popup.generatePopup(popupObj);
      },
    });
  },

  analyseCRUD : function ( selector ) {
    
    if ( $(selector + '[data-crud]').length > 0 ) {
      var cSelector = '.crud-btn[data-crud="c"]';
      var rSelector = '.crud-btn[data-crud="r"]';
      var uSelector = '.crud-btn[data-crud="u"]';
      var dSelector = '.crud-btn[data-crud="d"]';

      var crud = {
        c : $(selector).find(cSelector).hasClass ( 'btn-primary' ) ? '1' : '0',
        r : $(selector).find(rSelector).hasClass ( 'btn-primary' ) ? '1' : '0',
        u : $(selector).find(uSelector).hasClass ( 'btn-primary' ) ? '1' : '0',
        d : $(selector).find(dSelector).hasClass ( 'btn-primary' ) ? '1' : '0',
      };

      return parseInt ( crud.c + crud.r + crud.u + crud.d, 2 );
    }
    else {
      return false;
    }
  },

  setBtns : function ( selector, crudValue ) {
    if ( typeof crudValue === "number" ) {
      crudValue = Permissions.decToBin ( crudValue );
    }
    
    var crudArr = crudValue.split("");
    var crudLetters = ["c","r","u","d"];

    for ( var i = 0; i < crudArr.length; i++ ) {
      if ( crudArr[i] == 1 ) {
        $(selector).find ( 'button[data-crud="' + crudLetters[i] + '"]' )
          .removeClass ( 'btn-light' )
          .addClass ( 'btn-primary' );
      }
      else {
        $(selector).find ( 'button[data-crud="' + crudLetters[i] + '"]' )
          .removeClass ( 'btn-primary' )
          .addClass ( 'btn-light' );
      }
      
    }
  },

  decToBin : function ( dec ){
    return (dec >>> 0).toString(2);
  },
};

$(function() {
  Permissions.init();
});