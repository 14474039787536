var nav_utils = {
    init: function () {
        var sub_headers = document.querySelectorAll('.sub-header');

        var sub_sections_data = [];
        var subHeaderInit = function(sub_header, index) {
            sub_sections_data[index] = {
                orig_height: sub_header.querySelector('ul').offsetHeight
            };

            sub_header.querySelector('ul').style.height = 0;
            var sub_header_category = sub_header.querySelector("div");
            sub_header_category.addEventListener("click", function(event) {
                var sub_section = this.closest(".sub-header").querySelector('ul');
                var plus_cross_icon = this.closest(".sub-header").querySelector('.nav-plus-cross svg');

                if (sub_section.classList.contains("open")) {
                    sub_section.style.height = 0;
                    sub_section.classList.remove("open");
                    plus_cross_icon.style.transform = 'rotate(0deg)';
                } else {
                    sub_section.style.height = sub_sections_data[index].orig_height + 'px';
                    sub_section.classList.add("open");
                    console.log(plus_cross_icon);
                    console.log(plus_cross_icon.style);
                    plus_cross_icon.style.transform = 'rotate(315deg)';
                }
            });
        };

        sub_headers.forEach(subHeaderInit);
        nav_utils.activateNavBurgerBtn();
    },

    activateNavBurgerBtn: function() {
        var burger_btn = document.querySelector('label[for="cb-sub-menu"]');
        var nav = document.querySelector("nav");

        burger_btn.addEventListener("click", function(evt) {
            if (nav.attributes["data-state"].value === "open") {
                nav.attributes["data-state"].value = "closed";

                TweenMax.to("nav", 0.4, {
                    left: "-304px",
                });
            } else {
                nav.attributes["data-state"].value = "open";

                TweenMax.to("nav", 0.4, {
                    left: 0,
                });
            }
        });
    }
};

nav_utils.init();
