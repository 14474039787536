Qualifications.player = {
  id: null,
  data : {
    OCs    : {},
    OLs    : {},
    ILTs   : {},
    docs   : {},
    asmnts : {},
  },

  init : function ( id, jsonData ) {
    Qualifications.player.id = id;
    
    Qualifications.player.data = jsonData;
    Qualifications.player.OLs.init();
    Qualifications.player.docs.init();
    Qualifications.player.asmnts.init();
  },

  updateLockedItems : function() {
    $('#DT-qualification-items-list tbody tr').each ( function ( index, elem ) {
      // - SKIP THE FIRST ONE
      if ( index === 0 ) return true;
      // console.log('index', index);
      // console.log('prevLength', $(elem).prev().find('div[data-lock-icon="0"] svg.fa-check').length);
      // console.log('this', $(elem));
      // console.log('prev', $(elem).prev());
      if ( $(elem).prev().find('div[data-lock-icon="0"] svg.fa-check').length >= 1 ) {
        $(elem)
          .attr ( 'data-locked', '0' )
          .removeClass ( 'locked' );
        
        // - IF ALL ARE CHECKED, COMPLETE THE QUALIFICATION
        // console.log($('#DT-qualification-items-list tr[data-p="1"]').length, $('#DT-qualification-items-list tbody tr').length, $('#DT-qualification-items-list tr[data-p="1"]').length === $('#DT-qualification-items-list tbody tr').length);
        if ( $('#DT-qualification-items-list tr[data-p="1"]').length === $('#DT-qualification-items-list tbody tr').length ) {
          Qualifications.player.setPassed();
        }
      }
    });
      
  },

  setPassed : function() {
    var sendData = {
      qualificationID : Qualifications.player.id,
    };
    // console.log(sendData);
    $.ajax({
      url: '/AJAX-qualification/set-passed',
      type: 'post',
      data: sendData,
      dataType: 'json',
      success: function ( returnedData ) {
        // console.log(returnedData);
      },
      error: Errors.ajaxErrorHandler,
    });
    
  },

  OLs    : {
    init : function() {
      $('.ol-launch-btn')
      .off ( 'click' )
      .on  ( 'click', function ( evt ) {
        Elearning.launchCourse ( $(this).closest('tr').attr('data-user-course-id') );
      });
    }
  },

  OCs    : {
    init : function() {

    }
  },

  ILTs   : {
    init : function() {

    }
  },

  docs   : {
    init : function() {
      Qualifications.player.docs.setupDocuments();
    },

    setupDocuments : function() {
      var docRow = $('tr[data-type="doc"]');
      dataTable.tables['DT-qualification-items-list'].tbl.api().rows(docRow).every ( function ( rowIdx, tableLoop, rowLoop ) {
        var td = [
          $(this.node()).find('td')[0],
          $(this.node()).find('td')[1],
          $(this.node()).find('td')[2],
          $(this.node()).find('td')[3],
        ];

        var fileName   = $(td[1]).text();
        var filePath   = $(this.node()).find ( '.file-link' ).attr ( 'href' );
        var fileType   = $(this.node()).attr ( 'data-file-type' );
        var documentID = $(this.node()).attr ( 'data-doc-id' );

        $(this.node()).find ( '.file-link' )
          .attr ( 'href', '#' )
          .attr ( 'data-file-name', fileName )
          .attr ( 'data-file-path', filePath )
          .removeAttr ( 'target' )
          .off ( 'click' )
          .on  ( 'click', function ( evt ) {

            repos.accessFile ( fileName, filePath, fileType, documentID, {
              callback : function() {
                var hasBeenRead = Qualifications.player.data.docs[documentID].userDoc !== undefined &&
                                  Qualifications.player.data.docs[documentID].userDoc !== null &&
                                  Qualifications.player.data.docs[documentID].userDoc.read !== null;
                if ( hasBeenRead ) {
                  $('#confirm-read-doc-' + documentID + '-btn')
                    .addClass ( 'disabled' )
                    .addClass ( 'btn-light' )
                    .removeClass ( 'btn-primary' )
                    .empty()
                    .append ([
                      $('<span></span>')
                        .text ( 'You have read this document ' ),
                      $('<i></i>')
                        .addClass ( 'fa' )
                        .addClass ( 'fa-check' ),
                    ]);
                }
                else {
                  $('#confirm-read-doc-' + documentID + '-btn')
                    .removeClass ( 'disabled' )
                    .removeClass ( 'btn-light' )
                    .addClass ( 'btn-primary' );
                }
              },
              confirmRead : true,
              confirmReadCallback : function () {
                var hasBeenRead = Qualifications.player.data.docs[documentID].userDoc !== undefined &&
                                  Qualifications.player.data.docs[documentID].userDoc !== null &&
                                  Qualifications.player.data.docs[documentID].userDoc.read !== null;
                if ( hasBeenRead ) {
                  $('#confirm-read-doc-' + documentID + '-btn')
                    .off ( 'click' );
                }
                else {
                  $('#confirm-read-doc-' + documentID + '-btn')
                    .off ( 'click' )
                    .on  ( 'click', function ( evt ) {
                      $('#confirm-read-doc-' + documentID + '-btn')
                        .addClass ( 'disabled' )
                        .addClass ( 'btn-light' )
                        .removeClass ( 'btn-primary' )
                        .empty()
                        .append ([
                          $('<span></span>')
                            .text ( 'You have read this document ' ),
                          $('<i></i>')
                            .addClass ( 'fa' )
                            .addClass ( 'fa-check' ),
                        ]);

                      $('.file-link[data-document-id="' + documentID + '"]')
                        .closest('tr')
                        .attr ( 'data-p', '1' )
                        .find('.circle-icon')
                        .removeClass ( 'incomplete' )
                        .addClass ( 'passed' )
                          .find ( 'i' )
                          .removeClass ( 'ellipsis' )
                          .addClass ( 'fa fa-check' );

                      $('#confirm-read-doc-' + documentID + '-btn')
                        .off ( 'click' );

                      Qualifications.player.data.docs[documentID].read = 1;
                      Qualifications.player.docs.confirmRead ( documentID );
                    });
                }
              }
            });
          });

      });
    },// - END setupDocuments

    confirmRead : function ( docID ) {
      var sendData = {
        docID : docID,
      };

      $.ajax({
        url: '/AJAX-documents/confirm-read',
        type: 'post',
        data: sendData,
        dataType: 'json',
        success: function ( returnedData ) {
          $('tr[data-doc-id="' + docID + '"]')
            .attr ( 'data-p', '1' )
            .find ( '.sm-circle-icon' )
              .removeClass ( 'failed' )
              .addClass ( 'passed' )
              .find ( 'svg' )
                .removeClass ( 'fa-times' )
                .addClass ( 'fa-check' );
          
          Qualifications.player.updateLockedItems();
        },
      });

    },
  },

  asmnts : {
    init : function() {
      Qualifications.player.asmnts.refreshBtnEvents();
    },

    refreshBtnEvents : function() {
      $('.launch-assessment-btn')
        .off ( 'click' )
        .on  ( 'click', function ( evt ) {

          AssessmentPlayer.launchAssessment (
            $(this).closest('tr').attr ( 'data-asmnt-id' ),
            Qualifications.player.asmnts.setPassed,
            $(this).closest('tr').attr ( 'data-asmnt-id' ),
            function() {
              // console.log('ASSESSMENT ATTEMPT FAILED');
            },
            null,
            false
          );

        });
    },

    setPassed : function ( assessmentID ) {
      $('tr[data-asmnt-id="' + assessmentID + '"]' )
        .attr ( 'data-p', '1' )
        .find ( '.sm-circle-icon' )
          .removeClass ( 'failed' )
          .removeClass ( 'incomplete' )
          .addClass ( 'passed' )
          .find ( 'svg' )
            .removeClass ( 'fa-times' )
            .removeClass ( 'fa-ellipsis-h' )
            .addClass ( 'fa-check' );
      
      Qualifications.player.updateLockedItems();
    },
  },

  manualUsers : {
    getManualUsers : function() {
      var output = [];

      $('#DT-qual-add-manual-users tbody tr').each ( function ( index, elem ) {
        output.push({
          firstName : $(elem).find('td:nth-child(1) input').val(),
          surname : $(elem).find('td:nth-child(2) input').val(),
          email : $(elem).find('td:nth-child(3) input').val(),
          dob : $(elem).find('td:nth-child(4) input').val(),
        });
      });
      // console.log(output);
      
      return output;
    },
  },

};