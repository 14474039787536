var Qualifications = {
    editingID: null,

    data: {
        OCs: {},
        OLs: {},
        ILTs: {},
        docs: {},
        asmnts: {},
    },

    init: function () {
        Qualifications.OL.refreshBtnEvents();
        Qualifications.OC.refreshBtnEvents();
        Qualifications.ILT.refreshBtnEvents();
        Qualifications.docs.refreshBtnEvents();
        Qualifications.asmnts.refreshBtnEvents();

        dataTable.tables['DT-qualifications-added-OCs'] = {
            tbl: $('#DT-qualifications-added-OCs').dataTable({
                paging: false,
                info: false,
                filter: false,
            }),
        };

        dataTable.tables['DT-qualifications-added-OLs'] = {
            tbl: $('#DT-qualifications-added-OLs').dataTable({
                paging: false,
                info: false,
                filter: false,
            }),
        };

        dataTable.tables['DT-qualifications-added-ILTs'] = {
            tbl: $('#DT-qualifications-added-ILTs').dataTable({
                paging: false,
                info: false,
                filter: false,
            }),
        };
    },

    initAddQualificationItemsBtns: function () {
        Qualifications.OL.refreshBtnEvents();
        Qualifications.OC.refreshBtnEvents();
        Qualifications.ILT.refreshBtnEvents();
        Qualifications.docs.refreshBtnEvents();
        Qualifications.asmnts.refreshBtnEvents();
    },

    onReorder: function () {
        dataTable.tables['DT-qualification-items-list'].tbl.api().rows().every(function (rowIdx, tableLoop, rowLoop) {
            var node = this.node();
            var data = this.data();
            var id;

            switch ($(node).attr('data-type')) {
                case 'ol':
                    id = $(node).attr('data-course-id');
                    // console.log($(node).attr('data-type'), id, data[0], Qualifications.data.OLs[id].order);
                    Qualifications.data.OLs[id].order = rowIdx + 1;
                    // console.log('new order', Qualifications.data.OLs[id].order);
                    // console.log('----------------');
                    break;
                case 'oc':
                    id = $(node).attr('data-exercise-id') + '_' + $(node).attr('data-institution-id') + '_' + $(node).attr('data-site-id');
                    // console.log($(node).attr('data-type'), id, data[0], Qualifications.data.OCs[id].order);
                    Qualifications.data.OCs[id].order = rowIdx + 1;
                    // console.log('new order', Qualifications.data.OCs[id].order);
                    // console.log('----------------');
                    break;
                case 'ilt':
                    id = $(node).attr('data-ilt-course-id');
                    // console.log($(node).attr('data-type'), id, data[0], Qualifications.data.ILTs[id].order);
                    Qualifications.data.ILTs[id].order = rowIdx + 1;
                    // console.log('new order', Qualifications.data.ILTs[id].order);
                    // console.log('----------------');
                    break;
                case 'doc':
                    var id = $(node).attr('data-doc-id');
                    // console.log($(node).attr('data-type'), id, data[0], Qualifications.data.docs[id].order);
                    Qualifications.data.docs[id].order = rowIdx + 1;
                    // console.log('new order', Qualifications.data.docs[id].order);
                    // console.log('----------------');
                    break;
                case 'asmnt':
                    id = $(node).attr('data-asmnt-id');
                    // console.log($(node).attr('data-type'), id, data[0], Qualifications.data.asmnts[id].order);
                    Qualifications.data.asmnts[id].order = rowIdx + 1;
                    // console.log('new order', Qualifications.data.asmnts[id].order);
                    // console.log('----------------');
                    break;
            }
            // console.log(node);
            // console.log(this.data());
            // console.log(rowIdx, tableLoop, rowLoop);
        });
    },

    add: {
        init: function () {
            Qualifications.init();
            Qualifications.add.refreshBtnEvents();

            // - REMOVE "SELECT A SITE" AS AN OPTION SO IT FORCES YOU TO PICK ALL SITES
            $('#add-qualification-form #location-qualification-site option[value=""]').remove();

            $('#add-qualification-form').parsley().on('field:validated', function () {
                var ok = $('.parsley-error').length === 0;
                $('.bs-callout-info').toggleClass('hidden', !ok);
                $('.bs-callout-warning').toggleClass('hidden', ok);
            });
        },

        refreshBtnEvents: function () {
            $('#add-qualification-form').submit(function (evt) {
                evt.preventDefault();

                if ($(this).parsley().isValid()) {
                    if ($('.added-oc, .added-ol, .added-ilt, .added-doc, .added-asmnt').length === 0) {
                        main.flashData.show(
                            'You must add at least one training program to the qualification.', 'negative', 5
                        );
                    } else {
                        var send_data = {
                            name: $('#qualification-name').val(),
                            inst_id: $('#location-qualification-inst').val(),
                            site_id: $('#location-qualification-site').val(),
                            manualUsers: $('#cb-allow-manual-users').prop('checked'),
                            OCs: Qualifications.data.OCs,
                            OLs: Qualifications.data.OLs,
                            ILTs: Qualifications.data.ILTs,
                            docs: Qualifications.data.docs,
                            asmnts: Qualifications.data.asmnts,
                        };
                        // console.log(send_data);
                        $.ajax({
                            url: '/admin/AJAX-qualifications/save-new-qualification',
                            type: 'post',
                            data: send_data,
                            dataType: 'text',
                            success: function (returnedData) {
                                // console.log(returnedData);
                                returnedData = JSON.parse(returnedData);
                                // console.log(returnedData);
                                if (returnedData.success) {
                                    main.flashData.show(
                                        'The qualification "' + $('#qualification-name').val() + '" was created successfully', 'positive', 4
                                    );
                                } else {
                                    switch (returnedData.error.no) {
                                        default:
                                            main.flashData.show(
                                                returnedData.error.msg, 'negative', 4
                                            );

                                            // console.log(returnedData.error.extra);
                                            break;
                                            // - CAN PUT OTHER CASES 1-4 HERE IF WE WANT TO DO INDIVIDUAL TASKS FOR EACH
                                    }
                                }
                            },
                        });
                    }

                    return false;
                }
            });
        },
    },

    edit: {
        init: function (id, data) {
            Qualifications.editingID = id;
            Qualifications.data = data;

            Qualifications.data.OLs = Qualifications.data.OLs.length === undefined
                ? Qualifications.data.OLs
                : {};
            Qualifications.data.ILTs = Qualifications.data.ILTs.length === undefined
                ? Qualifications.data.ILTs
                : {};
            Qualifications.data.OCs = Qualifications.data.OCs.length === undefined
                ? Qualifications.data.OCs
                : {};
            Qualifications.data.docs = Qualifications.data.docs.length === undefined
                ? Qualifications.data.docs
                : {};
            Qualifications.data.asmnts = Qualifications.data.asmnts.length === undefined
                ? Qualifications.data.asmnts
                : {};

            Qualifications.init();
            Qualifications.edit.refreshBtnEvents();

            Qualifications.OL.refreshBtnEvents();
            Qualifications.OC.refreshBtnEvents();
            Qualifications.ILT.refreshBtnEvents();
            Qualifications.docs.refreshBtnEvents();
            Qualifications.asmnts.refreshBtnEvents();

            $('#edit-qualification-form').parsley().on('field:validated', function () {
                var ok = $('.parsley-error').length === 0;
                $('.bs-callout-info').toggleClass('hidden', !ok);
                $('.bs-callout-warning').toggleClass('hidden', ok);
            });
        },

        refreshBtnEvents: function () {
            $('#edit-qualification-form').submit(function (evt) {
                evt.preventDefault();

                if ($(this).parsley().isValid()) {
                    if ($('.added-ol, .added-oc, .added-ilt, .added-doc, .added-asmnt').length === 0) {
                        main.flashData.show(
                            'You must add at least one training package to the qualification.', 'negative', 5
                        );
                    } else {
                        var send_data = {
                            id: Qualifications.editingID,
                            name: $('#qualification-name').val(),
                            OCs: Qualifications.data.OCs,
                            OLs: Qualifications.data.OLs,
                            ILTs: Qualifications.data.ILTs,
                            docs: Qualifications.data.docs,
                            asmnts: Qualifications.data.asmnts,
                        };
                        console.log(send_data);
                        $.ajax({
                            url: '/admin/AJAX-qualifications/save-existing-qualification',
                            type: 'post',
                            data: send_data,
                            dataType: 'text',
                            success: function (returnedData) {
                                // console.log(returnedData);
                                returnedData = JSON.parse(returnedData);
                                // console.log(returnedData);
                                if (returnedData.success) {
                                    main.flashData.show(
                                        'The training units you\'ve selected were successfully grouped as: ' + $('#qualification-name').val(), 'positive', 4
                                    );
                                }
                            },
                            error: function (err) {
                                console.warn(err.responseText);
                            }
                        });
                    }

                    return false;
                }
            });
        },
    },

    list: {
        refreshBtnEvents: function () {
            $('.delete-tpaq-btn')
                .off('click')
                .on('click', function (evt) {
                    var qualificationID = $(this).closest('tr').attr('data-id');

                    popup.generatePopup({
                        title: 'Confirm Action',
                        method: 'ajax',
                        ajaxUrl: '/AJAX-admin/show-confirm-high-level-action-with-password',
                        width: 450,
                        height: 200,
                        success: function (params) {
                            $('#confirm-action-with-pw-btn')
                                .off('click')
                                .on('click', function (evt) {
                                    var send_data = {
                                        confirm: $('input[name="confirm-password"]').val(),
                                        targetMethod: 'delete-qualification',
                                        params: params,
                                    };

                                    $.ajax({
                                        url: '/AJAX-admin/confirm-high-level-action-with-password',
                                        type: 'post',
                                        data: send_data,
                                        dataType: 'json',
                                        success: function (returnedData) {
                                            var msg, msgState;
                                            // console.log(returnedData);
                                            if (returnedData.success) {
                                                msg = "The site has been successfully archived";
                                                msgState = "positive";

                                                dataTable.tables['DT-qualifications'].tbl.api().row('[data-id="' + returnedData.qualificationID + '"]').remove().draw();
                                            } else {
                                                msgState = "negative";

                                                switch (returnedData.reason) {
                                                    case 'bad-permission':
                                                        msg = "You don't possess the required permissions to perform this task";
                                                        break;
                                                    case 'password-incorrect':
                                                        msg = "The password you entered was incorrect";
                                                        break;
                                                }
                                            }

                                            popup.close();

                                            main.flashData.show(msg, msgState, 5);
                                        },
                                        error: Errors.ajaxErrorHandler,
                                    });

                                    // console.log(params.qualificationID);
                                });
                        },
                        successParams: {
                            qualificationID: qualificationID,
                        },
                    });
                })
        },
    },

    OL: {
        refreshBtnEvents: function () {
            $('#show-OLs-pool-btn')
                .off('click')
                .on('click', function (evt) {
                    evt.preventDefault();

                    popup.generatePopup({
                        title: 'Select Online Learning Courses to add to this qualification',
                        method: 'ajax',
                        ajaxUrl: '/admin/AJAX-qualifications/show-OLs-pool',
                        maxWidth: 1000,
                        maxHeight: 650,

                        success: function () {
                            popup.hideFooter();
                        },
                    });
                });

            $('tr[data-type="ol"] .remove-added-item-btn')
                .off('click')
                .on('click', function (evt) {
                    evt.preventDefault();

                    var courseID = Number($(this).closest('tr').attr('data-course-id'));

                    Qualifications.OL.removeFromQualification(courseID);
                });
        },

        removeFromQualification: function (courseID) {
            if (Qualifications.data.OLs[courseID] !== undefined) {
                delete Qualifications.data.OLs[courseID];

                var tr = 'tr[data-course-id=' + courseID + '][data-type="ol"]';

                dataTable.tables['DT-qualification-items-list'].tbl.api().row(tr).remove().draw();
            }
        },

        pool: {
            init: function () {
                Qualifications.OL.pool.refreshBtnEvents();
                Qualifications.OL.pool.initialiseBtnPositions();
            },

            refreshBtnEvents: function () {
                $('.add-OL-btn')
                    .off('click')
                    .on('click', function (evt) {
                        var courseID = $(this).closest('tr').attr('data-course-id');

                        if (Qualifications.data.OLs[courseID] === undefined) {
                            var destLength = dataTable.tables['DT-qualification-items-list'].tbl.api().rows().nodes().length;

                            Qualifications.data.OLs[courseID] = {
                                courseID: courseID,
                                order: destLength + 1
                            };

                            $(this)
                                .removeClass('btn-primary')
                                .addClass('disabled-btn');

                            $(this).closest('td').find('.remove-OL-btn')
                                .removeClass('disabled-btn')
                                .addClass('btn-danger');

                            var tr = $(this).closest('tr');
                            var rowData = dataTable.tables['DT-OLs-pool'].tbl.api().row($(tr)).data();

                            var removeBtn = '<div class="btn-bank-host tr">' +
                                '<button class="remove-added-item-btn lite-btn btn-danger btn-bank">' +
                                '<i class="fa fa-trash"></i>' +
                                '</button>' +
                                '</div>';

                            var rowNode = dataTable.tables['DT-qualification-items-list'].tbl.api().row.add([
                                destLength + 1,
                                '<i class="fa fa-stream"></i>',
                                rowData[0],
                                'Online Learning Course',
                                removeBtn,
                            ]).draw().node();

                            $(rowNode)
                                .addClass('added-ol')
                                .attr('data-course-id', $(this).closest('tr').attr('data-course-id'))
                                .attr('data-type', 'ol');

                            $(rowNode).find('.remove-added-item-btn')
                                .off('click')
                                .on('click', function (evt) {
                                    evt.preventDefault();

                                    var courseID = $(this).closest('tr').attr('data-course-id');

                                    Qualifications.OL.removeFromQualification(courseID);
                                });
                        }
                    });

                $('.remove-OL-btn')
                    .off('click')
                    .on('click', function (evt) {
                        var courseID = $(this).closest('tr').attr('data-course-id');

                        Qualifications.OL.removeFromQualification(courseID);

                        $(this)
                            .removeClass('btn-danger')
                            .addClass('disabled-btn');

                        $(this).closest('td').find('.add-OL-btn')
                            .removeClass('disabled-btn')
                            .addClass('btn-primary');
                    });
            },

            initialiseBtnPositions: function () {

                var selector, rowNode;

                for (var courseID in Qualifications.data.OLs) {
                    selector = 'tr[data-course-id=' + courseID + ']';
                    rowNode = dataTable.tables['DT-OLs-pool'].tbl.api().row(selector).node();

                    $(rowNode).find('.add-OL-btn')
                        .addClass('disabled-btn')
                        .removeClass('btn-primary');

                    $(rowNode).find('.remove-OL-btn')
                        .removeClass('disabled-btn')
                        .addClass('btn-danger');
                }

            },
        },

    },

    OC: {
        refreshBtnEvents: function () {
            $('#show-OCs-pool-btn')
                .off('click')
                .on('click', function (evt) {
                    evt.preventDefault();

                    popup.generatePopup({
                        title: 'Select competencies to add to this qualification',
                        method: 'ajax',
                        ajaxUrl: '/admin/AJAX-qualifications/show-OCs-pool',
                        maxWidth: 1000,
                        maxHeight: 650,

                        success: function () {
                            popup.hideFooter();
                        },
                    });
                });

            $('tr[data-type="oc"] .remove-added-item-btn')
                .off('click')
                .on('click', function (evt) {
                    evt.preventDefault();

                    var exercise_id = Number($(this).closest('tr').attr('data-exercise-id')),
                        institution_id = Number($(this).closest('tr').attr('data-institution-id')),
                        site_id = Number($(this).closest('tr').attr('data-site-id'));

                    Qualifications.OC.removeFromQualification(exercise_id, institution_id, site_id);
                });
        },

        removeFromQualification: function (exercise_id, institution_id, site_id) {
            var ex_loc_key = [exercise_id, institution_id, site_id].join("_");

            if (Qualifications.data.OCs[ex_loc_key] !== undefined) {
                delete Qualifications.data.OCs[ex_loc_key];

                var tr = 'tr' +
                    '[data-exercise-id=' + exercise_id + ']' +
                    '[data-institution-id=' + institution_id + ']' +
                    '[data-site-id=' + site_id + ']' +
                    '[data-type="oc"]';

                dataTable.tables['DT-qualification-items-list'].tbl.api().row(tr).remove().draw();
            }
        },

        pool: {
            init: function () {
                Qualifications.OC.pool.refreshBtnEvents();
                Qualifications.OC.pool.initialiseBtnPositions();
            },

            refreshBtnEvents: function () {
                $('.add-OC-btn')
                    .off('click')
                    .on('click', function (evt) {
                        var exLocIdent = $(this).closest('tr').attr('data-exercise-id') + '_' +
                            $(this).closest('tr').attr('data-institution-id') + '_' +
                            $(this).closest('tr').attr('data-site-id');

                        if (Qualifications.data.OCs[exLocIdent] === undefined) {
                            var destLength = dataTable.tables['DT-qualification-items-list'].tbl.api().rows().nodes().length;

                            Qualifications.data.OCs[exLocIdent] = {
                                exLocIdent: exLocIdent,
                                order: destLength + 1
                            };

                            $(this)
                                .removeClass('btn-primary')
                                .addClass('disabled-btn');

                            $(this).closest('td').find('.remove-OC-btn')
                                .removeClass('disabled-btn')
                                .addClass('btn-danger');

                            var tr = $(this).closest('tr');
                            var rowData = dataTable.tables['DT-OCs-pool'].tbl.api().row($(tr)).data();
                            var removeBtn = '<div class="btn-bank-host tr">' +
                                '<button class="remove-added-item-btn lite-btn btn-danger btn-bank">' +
                                '<i class="fa fa-trash"></i>' +
                                '</button>' +
                                '</div>';

                            var rowNode = dataTable.tables['DT-qualification-items-list'].tbl.api().row.add([
                                destLength + 1,
                                '<i class="fa fa-stream"></i>',
                                rowData[0] + ' - ' + rowData[1],
                                'Occupational Competency',
                                removeBtn,
                            ]).draw().node();

                            $(rowNode)
                                .addClass('added-oc')
                                .attr('data-exercise-id', $(this).closest('tr').attr('data-exercise-id'))
                                .attr('data-institution-id', $(this).closest('tr').attr('data-institution-id'))
                                .attr('data-site-id', $(this).closest('tr').attr('data-site-id'))
                                .attr('data-type', 'oc');

                            $(rowNode).find('.remove-added-item-btn')
                                .off('click')
                                .on('click', function (evt) {
                                    evt.preventDefault();

                                    var exLocIdent = $(this).closest('tr').attr('data-exercise-id') + '_' +
                                        $(this).closest('tr').attr('data-institution-id') + '_' +
                                        $(this).closest('tr').attr('data-site-id');

                                    Qualifications.OC.removeFromQualification(exLocIdent);
                                });
                        }
                    });

                $('.remove-OC-btn')
                    .off('click')
                    .on('click', function (evt) {
                        var exLocIdent = $(this).closest('tr').attr('data-exercise-id') + '_' +
                            $(this).closest('tr').attr('data-institution-id') + '_' +
                            $(this).closest('tr').attr('data-site-id');

                        Qualifications.OC.removeFromQualification(exLocIdent);

                        $(this)
                            .removeClass('btn-danger')
                            .addClass('disabled-btn');

                        $(this).closest('td').find('.add-OC-btn')
                            .removeClass('disabled-btn')
                            .addClass('btn-primary');
                    });
            },

            initialiseBtnPositions: function () {

                var selector, rowNode, exLocIdentArr;

                for (var exLocIdent in Qualifications.data.OCs) {
                    exLocIdentArr = exLocIdent.split('_');
                    selector = 'tr' +
                        '[data-exercise-id=' + exLocIdentArr[0] + ']' +
                        '[data-institution-id=' + exLocIdentArr[1] + ']' +
                        '[data-site-id=' + exLocIdentArr[2] + ']';

                    rowNode = dataTable.tables['DT-OCs-pool'].tbl.api().row(selector).node();

                    $(rowNode).find('.add-OC-btn')
                        .addClass('disabled-btn')
                        .removeClass('btn-primary');

                    $(rowNode).find('.remove-OC-btn')
                        .removeClass('disabled-btn')
                        .addClass('btn-danger');
                }

            },
        },

    },

    ILT: {
        refreshBtnEvents: function () {
            $('#show-ILTs-pool-btn')
                .off('click')
                .on('click', function (evt) {
                    evt.preventDefault();

                    popup.generatePopup({
                        title: 'Select ILT Courses to add to this qualification',
                        method: 'ajax',
                        ajaxUrl: '/admin/AJAX-qualifications/show-ILTs-pool',
                        maxWidth: 1000,
                        maxHeight: 650,

                        success: function () {
                            popup.hideFooter();
                        },
                    });
                });

            $('tr[data-type="ilt"] .remove-added-item-btn')
                .off('click')
                .on('click', function (evt) {
                    evt.preventDefault();

                    var iltCourseID = Number($(this).closest('tr').attr('data-ILT-course-id'));

                    Qualifications.ILT.removeFromQualification(iltCourseID);
                });
        },

        removeFromQualification: function (iltCourseID) {
            if (Qualifications.data.ILTs[iltCourseID] !== undefined) {
                delete Qualifications.data.ILTs[iltCourseID];

                var tr = 'tr[data-ilt-course-id=' + iltCourseID + '][data-type="ilt"]';

                dataTable.tables['DT-qualification-items-list'].tbl.api().row(tr).remove().draw();
            }
        },

        pool: {
            init: function () {
                Qualifications.ILT.pool.refreshBtnEvents();
                Qualifications.ILT.pool.initialiseBtnPositions();
            },

            refreshBtnEvents: function () {
                $('.add-ILT-btn')
                    .off('click')
                    .on('click', function (evt) {
                        var iltCourseID = $(this).closest('tr').attr('data-ilt-course-id');

                        if (Qualifications.data.ILTs[iltCourseID] === undefined) {
                            var destLength = dataTable.tables['DT-qualification-items-list'].tbl.api().rows().nodes().length;

                            Qualifications.data.ILTs[iltCourseID] = {
                                iltCourseID: iltCourseID,
                                order: destLength + 1
                            };

                            $(this)
                                .removeClass('btn-primary')
                                .addClass('disabled-btn');

                            $(this).closest('td').find('.remove-ILT-btn')
                                .removeClass('disabled-btn')
                                .addClass('btn-danger');

                            var tr = $(this).closest('tr');
                            var rowData = dataTable.tables['DT-ILTs-pool'].tbl.api().row($(tr)).data();

                            var removeBtn = '<div class="btn-bank-host tr">' +
                                '<button class="remove-added-item-btn lite-btn btn-danger btn-bank">' +
                                '<i class="fa fa-trash"></i>' +
                                '</button>' +
                                '</div>';

                            var rowNode = dataTable.tables['DT-qualification-items-list'].tbl.api().row.add([
                                destLength + 1,
                                '<i class="fa fa-stream"></i>',
                                rowData[0],
                                'ILT Course',
                                removeBtn,
                            ]).draw().node();

                            $(rowNode)
                                .addClass('added-ilt')
                                .attr('data-ilt-course-id', $(this).closest('tr').attr('data-ilt-course-id'))
                                .attr('data-type', 'ilt');

                            $(rowNode).find('.remove-added-item-btn')
                                .off('click')
                                .on('click', function (evt) {
                                    evt.preventDefault();

                                    var iltCourseID = $(this).closest('tr').attr('data-ilt-course-id');

                                    Qualifications.ILT.removeFromQualification(iltCourseID);
                                });
                        }
                    });

                $('.remove-ILT-btn')
                    .off('click')
                    .on('click', function (evt) {
                        var iltCourseID = $(this).closest('tr').attr('data-ilt-course-id');

                        Qualifications.ILT.removeFromQualification(iltCourseID);

                        $(this)
                            .removeClass('btn-danger')
                            .addClass('disabled-btn');

                        $(this).closest('td').find('.add-ILT-btn')
                            .removeClass('disabled-btn')
                            .addClass('btn-primary');
                    });
            },

            initialiseBtnPositions: function () {
                var selector, rowNode;

                for (var iltCourseID in Qualifications.data.ILTs) {
                    selector = 'tr[data-ilt-course-id=' + iltCourseID + ']';
                    rowNode = dataTable.tables['DT-ILTs-pool'].tbl.api().row(selector).node();

                    $(rowNode).find('.add-ILT-btn')
                        .addClass('disabled-btn')
                        .removeClass('btn-primary');

                    $(rowNode).find('.remove-ILT-btn')
                        .removeClass('disabled-btn')
                        .addClass('btn-danger');
                }
            },

        },

    },

    docs: {
        refreshBtnEvents: function () {
            $('#show-docs-pool-btn')
                .off('click')
                .on('click', function (evt) {
                    evt.preventDefault();

                    popup.generatePopup({
                        title: 'Select documents to add to this qualification',
                        method: 'ajax',
                        ajaxUrl: '/admin/AJAX-qualifications/show-docs-pool',
                        maxWidth: 1000,
                        maxHeight: 650,

                        success: function () {
                            popup.hideFooter();
                        },
                    });
                });

            $('tr[data-type="doc"] .remove-added-item-btn')
                .off('click')
                .on('click', function (evt) {
                    evt.preventDefault();

                    var docID = Number($(this).closest('tr').attr('data-doc-id'));

                    Qualifications.docs.removeFromQualification(docID);
                });
        },

        removeFromQualification: function (docID) {
            if (Qualifications.data.docs[docID] !== undefined) {
                delete Qualifications.data.docs[docID];

                var tr = 'tr[data-doc-id=' + docID + '][data-type="doc"]';

                dataTable.tables['DT-qualification-items-list'].tbl.api().row(tr).remove().draw();
            }
        },

        pool: {
            init: function () {
                Qualifications.docs.pool.refreshBtnEvents();
                Qualifications.docs.pool.initialiseBtnPositions();
            },

            refreshBtnEvents: function () {
                $('.add-doc-btn')
                    .off('click')
                    .on('click', function (evt) {
                        var docID = $(this).closest('tr').attr('data-doc-id');

                        if (Qualifications.data.docs[docID] === undefined) {
                            var destLength = dataTable.tables['DT-qualification-items-list'].tbl.api().rows().nodes().length;

                            Qualifications.data.docs[docID] = {
                                docID: docID,
                                order: destLength + 1
                            };

                            $(this)
                                .removeClass('btn-primary')
                                .addClass('disabled-btn');

                            $(this).closest('td').find('.remove-doc-btn')
                                .removeClass('disabled-btn')
                                .addClass('btn-danger');

                            var tr = $(this).closest('tr');
                            var rowData = dataTable.tables['DT-docs-pool'].tbl.api().row($(tr)).data();
                            var removeBtn = '<div class="btn-bank-host tr">' +
                                '<button class="remove-added-item-btn lite-btn btn-danger btn-bank">' +
                                '<i class="fa fa-trash"></i>' +
                                '</button>' +
                                '</div>';

                            var rowNode = dataTable.tables['DT-qualification-items-list'].tbl.api().row.add([
                                destLength + 1,
                                '<i class="fa fa-stream"></i>',
                                rowData[0],
                                'Document',
                                removeBtn,
                            ]).draw().node();

                            $(rowNode)
                                .addClass('added-doc')
                                .attr('data-doc-id', $(this).closest('tr').attr('data-doc-id'))
                                .attr('data-type', 'doc');

                            $(rowNode).find('.remove-added-item-btn')
                                .off('click')
                                .on('click', function (evt) {
                                    evt.preventDefault();

                                    var docID = $(this).closest('tr').attr('data-doc-id');

                                    Qualifications.docs.removeFromQualification(docID);
                                });
                        }
                    });

                $('.remove-doc-btn')
                    .off('click')
                    .on('click', function (evt) {
                        var docID = $(this).closest('tr').attr('data-doc-id');

                        Qualifications.docs.removeFromQualification(docID);

                        $(this)
                            .removeClass('btn-danger')
                            .addClass('disabled-btn');

                        $(this).closest('td').find('.add-doc-btn')
                            .removeClass('disabled-btn')
                            .addClass('btn-primary');
                    });
            },

            initialiseBtnPositions: function () {
                var selector, rowNode;

                for (var docID in Qualifications.data.docs) {
                    selector = 'tr[data-doc-id=' + Qualifications.data.docs[docID] + ']';
                    rowNode = dataTable.tables['DT-docs-pool'].tbl.api().row(selector).node();

                    $(rowNode).find('.add-doc-btn')
                        .addClass('disabled-btn')
                        .removeClass('btn-primary');

                    $(rowNode).find('.remove-doc-btn')
                        .removeClass('disabled-btn')
                        .addClass('btn-danger');
                }

            },

        },

    },

    asmnts: {
        refreshBtnEvents: function () {
            $('#show-asmnts-pool-btn')
                .off('click')
                .on('click', function (evt) {
                    evt.preventDefault();

                    popup.generatePopup({
                        title: 'Select assessments to add to this qualification',
                        method: 'ajax',
                        ajaxUrl: '/admin/AJAX-qualifications/show-asmnts-pool',
                        maxWidth: 1000,
                        maxHeight: 650,

                        success: function () {
                            popup.hideFooter();
                        },
                    });
                });

            $('tr[data-type="asmnt"] .remove-added-item-btn')
                .off('click')
                .on('click', function (evt) {
                    evt.preventDefault();

                    var assessmentID = Number($(this).closest('tr').attr('data-asmnt-id'));

                    Qualifications.asmnts.removeFromQualification(assessmentID);
                });
        },

        removeFromQualification: function (assessmentID) {
            if (Qualifications.data.asmnts[assessmentID] !== undefined) {
                delete Qualifications.data.asmnts[assessmentID];

                var tr = 'tr[data-asmnt-id=' + assessmentID + '][data-type="asmnt"]';

                dataTable.tables['DT-qualification-items-list'].tbl.api().row(tr).remove().draw();
            }
        },

        pool: {
            init: function () {
                Qualifications.asmnts.pool.refreshBtnEvents();
                Qualifications.asmnts.pool.initialiseBtnPositions();
            },

            refreshBtnEvents: function () {
                $('.add-asmnt-btn')
                    .off('click')
                    .on('click', function (evt) {
                        var assessmentID = $(this).closest('tr').attr('data-asmnt-id');

                        if (Qualifications.data.asmnts[assessmentID] === undefined) {
                            var destLength = dataTable.tables['DT-qualification-items-list'].tbl.api().rows().nodes().length;

                            Qualifications.data.asmnts[assessmentID] = {
                                assessmentID: assessmentID,
                                order: destLength + 1
                            };

                            $(this)
                                .removeClass('btn-primary')
                                .addClass('disabled-btn');

                            $(this).closest('td').find('.remove-asmnt-btn')
                                .removeClass('disabled-btn')
                                .addClass('btn-danger');

                            var tr = $(this).closest('tr');
                            var rowData = dataTable.tables['DT-asmnts-pool'].tbl.api().row($(tr)).data();
                            var removeBtn = '<div class="btn-bank-host tr">' +
                                '<button class="remove-added-item-btn lite-btn btn-danger btn-bank">' +
                                '<i class="fa fa-trash"></i>' +
                                '</button>' +
                                '</div>';

                            var rowNode = dataTable.tables['DT-qualification-items-list'].tbl.api().row.add([
                                destLength + 1,
                                '<i class="fa fa-stream"></i>',
                                rowData[0],
                                'Assessment',
                                removeBtn,
                            ]).draw().node();

                            $(rowNode)
                                .addClass('added-asmnt')
                                .attr('data-asmnt-id', $(this).closest('tr').attr('data-asmnt-id'))
                                .attr('data-type', 'asmnt');

                            $(rowNode).find('.remove-added-item-btn')
                                .off('click')
                                .on('click', function (evt) {
                                    evt.preventDefault();

                                    var assessmentID = $(this).closest('tr').attr('data-asmnt-id');

                                    Qualifications.asmnts.removeFromQualification(assessmentID);
                                });
                        }
                    });

                $('.remove-asmnt-btn')
                    .off('click')
                    .on('click', function (evt) {
                        var assessmentID = $(this).closest('tr').attr('data-asmnt-id');

                        Qualifications.asmnts.removeFromQualification(assessmentID);

                        $(this)
                            .removeClass('btn-danger')
                            .addClass('disabled-btn');

                        $(this).closest('td').find('.add-asmnt-btn')
                            .removeClass('disabled-btn')
                            .addClass('btn-primary');
                    });
            },

            initialiseBtnPositions: function () {
                var selector, rowNode;

                for (var assessmentID in Qualifications.data.asmnts) {
                    selector = 'tr[data-asmnt-id=' + Qualifications.data.asmnts[assessmentID] + ']';
                    rowNode = dataTable.tables['DT-asmnts-pool'].tbl.api().row(selector).node();

                    $(rowNode).find('.add-asmnt-btn')
                        .addClass('disabled-btn')
                        .removeClass('btn-primary');

                    $(rowNode).find('.remove-asmnt-btn')
                        .removeClass('disabled-btn')
                        .addClass('btn-danger');
                }

            },

        },

    },
};
